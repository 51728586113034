import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	GET_ZONES_CARD_ACTION,
	GET_ZONES_CARDS_SAGA,
	SET_CURRENT_REALTY,
	SET_CURRENT_ZONE,
} from '../../pages/home/redux-sagas/actionTypes';
import { GetZonesCardSagaType } from '../../pages/home/redux-sagas/sagas/poll/getZonesCardsAsync';
import { ZoneType } from '../../types/types';
import { appGlobalStore } from '../../store/root-reducer';
import {
	getZonesCard,
	getZonesCardType,
} from '../../pages/home/redux-sagas/actions/realty/getZonesCard';
import { useWindowDimensions } from 'utils/useDimensions';

import Rooms from './rooms/rooms';
import Logout from './logout/logout';
import UserPrompt from './user-prompt/user-prompt';

import { logo1Svg, logo2Svg, logo3svg } from './images/logos';
import { ArrowSvg } from './images/index';
import styles from './side-left-panel.module.scss';
import { setCurrentRealty } from 'pages/home/redux-sagas/actions/realty/setCurrentRealty';
import { setCurrentZone } from 'pages/home/redux-sagas/actions/realty/setCurrentZone';

const SideLeftPanel = () => {
	const dispatch = useDispatch();
	const zones: ZoneType[] = useSelector(
		({ homePage }: appGlobalStore) => homePage.zones,
	);
	const [disclosure, setDisclosure] = useState(false);
	const [stateElem, setStateElem] = useState(false);
	const [activeRoom, setActiveRoom] = useState(0);

	const [isWideScreen, setIsWideScreen] = useState(true);
	const { width } = useWindowDimensions();

	useEffect(() => {
		setIsWideScreen(width > parseInt(styles.changeHeaderWidth));
	}, [width]);

	useEffect(() => {
		document.addEventListener('keyup', closeLeftPanelEsc);
		return () => {
			document.removeEventListener('keyup', closeLeftPanelEsc);
		};
	}, [disclosure]);

	useEffect(() => {
		setActiveRoom(0);
		if (zones.length) {
			dispatch({
				type: SET_CURRENT_ZONE,
				payload: { zone_id: zones[0].id },
			});
		}
	}, [zones, dispatch]);

	const closeLeftPanelEsc = (e) => {
		if (disclosure && e.keyCode === 27) {
			setDisclosure((disclosure) => !disclosure);
			setStateElem((stateElem) => !stateElem);
		} else if (!disclosure && e.keyCode === 27) {
			setDisclosure(false);
			setStateElem(false);
		}
	};

	const handleDisclosure = (e) => {
		setDisclosure((disclosure) => !disclosure);
		const sayHi = () => setStateElem((stateElem) => !stateElem);
		!stateElem
			? setTimeout(sayHi, 500)
			: setStateElem((stateElem) => !stateElem);
	};

	const handleZone = (realty_id: number, zones_id: number, index: number) => {
		dispatch(
			setCurrentRealty({
				type: SET_CURRENT_REALTY,
				payload: {
					realty_id: realty_id,
				},
			}),
		);

		dispatch(
			setCurrentZone({
				type: SET_CURRENT_ZONE,
				payload: {
					zone_id: zones_id,
				},
			}),
		);

		setActiveRoom(index);
		const zonesCardAction: getZonesCardType = {
			type: GET_ZONES_CARD_ACTION,
			payload: { cards: {} },
		};
		dispatch(getZonesCard(zonesCardAction));

		const zonesCardSaga: GetZonesCardSagaType = {
			type: GET_ZONES_CARDS_SAGA,
			payload: {
				realty_id: realty_id,
				zones_id: zones_id,
			},
		};
		dispatch(zonesCardSaga);
	};

	return (
		<>
			{isWideScreen ? (
				<section className={disclosure ? styles.panelLarge : styles.panelSmall}>
					<div className={stateElem ? styles.headerLarge : styles.headerSmall}>
						<img
							src={stateElem ? logo3svg : logo3svg}
							alt="Логотип SCS Climatec"
						/>
					</div>
					<div className={stateElem ? styles.mainLarge : styles.mainSmall}>
						<Rooms
							stateElem={stateElem}
							zones={zones}
							activeRoom={activeRoom}
							handleZone={handleZone}
							isLarge={true}
						/>
						<div className={styles.logoutWrapper}>
							<Logout stateElem={stateElem} />
							<UserPrompt stateElem={stateElem} />
						</div>
					</div>
					<button className={styles.disclosure} onClick={handleDisclosure}>
						<div className={styles.arrow}>
							<ArrowSvg disclosure={disclosure} />
						</div>
					</button>
				</section>
			) : (
				<div className={styles.panelTop}>
					<Rooms
						stateElem={stateElem}
						zones={zones}
						activeRoom={activeRoom}
						handleZone={handleZone}
						isLarge={false}
					/>
				</div>
			)}
		</>
	);
};

export default SideLeftPanel;
