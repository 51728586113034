import React, { FC } from "react";
import styles from "../../card.module.scss";
import {
  BlockType,
  ContentCardType,
} from "../../../../../types/cardTypes";
import RenderBlock from "../render-block";

type RenderContentType = {
  content: ContentCardType;
  disclosure: boolean;
  patchVariableBlock: (block, value, type) => void;
};

const RenderContent: FC<RenderContentType> = ({
  content,
  disclosure,
  patchVariableBlock,
}) => {
  return (
    <>
      {content.blocks.some((item) => item.type === "color") ? (
        <div
          className={`${styles.item}`}
          style={{
            borderTop: disclosure
              ? "1px solid #1A1A1A"
              : "1px solid transparent",
            alignItems: content?.blocks.some((item) => item.type === "color")
              ? "flex-start"
              : "center",
            justifyContent: "space-between",
          }}
        >
          <div className={styles.domesticItem}>
            {content?.blocks
              .filter((item) => item.type !== "color")
              .map(
                (
                  item: BlockType
                ) => (
                  <RenderBlock
                    block={item}
                    patchVariableBlock={patchVariableBlock}
                    key={item.id}
                  />
                )
              )}
          </div>
          <div>
            {content?.blocks
              .filter((item) => item.type === "color")
              .map(
                (
                  item: BlockType
                ) => (
                  <RenderBlock
                    block={item}
                    patchVariableBlock={patchVariableBlock}
                    key={item.id}
                  />
                )
              )}
          </div>
        </div>
      ) : (
        <div
          className={styles.item}
          style={{
            borderTop: disclosure
              ? "1px solid #1A1A1A"
              : "1px solid transparent",
          }}
        >
          {content?.blocks.map(
            (
              item: BlockType
            ) => (
              <RenderBlock
                block={item}
                patchVariableBlock={patchVariableBlock}
                key={item.id}
              />
            )
          )}
        </div>
      )}
    </>
  );
};

export default RenderContent;
