import React, { FC, useState, useRef } from 'react';
import styles from './range.module.scss';
import { ScaleBlockType } from '../../../../../../types/cardTypes';
import { useDebounce } from 'use-debounce';
import { useEffect } from 'react';
import NoVariable from '../no-variable/no-variable';
import OnlineStatus from '../online-status/online-status';
import { minus, plus } from '../../../images/icon-card';

type RangeType = {
	item: ScaleBlockType;
	patchVariableBlock: (block: ScaleBlockType, value, type) => void;
};

const Range: FC<RangeType> = ({ item, patchVariableBlock }) => {
	const { value, type, precision: initPrecision } = item.variable;
	const isVarInteger = type === 'integer';
	const varValue = isVarInteger
		? parseInt(value).toString()
		: value.replace(/(\.\d+?)0+$/, '$1');
	const precision = isVarInteger ? 0 : initPrecision ?? 1;
	const step = 1 / 10 ** precision;

	const isFirstRun = useRef(true);
	const [valueRange, setValueRange] = useState(varValue || '0');
	const [manualValue, setManualValue] = useState(valueRange);
	const [values] = useDebounce(valueRange, 1000);
	const changeRange = (e) => {
		const { name, value } = e.target;
		const valueWithDot = value.replace(',', '.');

		if (name === 'range') {
			setValueRange(valueWithDot);
			setManualValue(valueWithDot);
			return;
		}

		if (isVarInteger && name === 'text') {
			const regex = new RegExp(`^(0?|([1-9][0-9]*)*)$`);
			if (
				regex.test(valueWithDot) &&
				+valueWithDot >= item?.min &&
				+valueWithDot <= item.max
			) {
				setManualValue(valueWithDot);
			}
			return;
		}

		if (!isVarInteger && name === 'text') {
			const regex = new RegExp(
				`^(0?|([1-9][0-9]*)*)(\\.\\d{0,${precision}})?$`,
			);
			if (
				regex.test(valueWithDot) &&
				+valueWithDot >= item?.min &&
				+valueWithDot <= item.max
			) {
				setManualValue(valueWithDot);
			}
		}
	};

	const onManualInputSubmit = (e) => {
		e.preventDefault();
		if (manualValue === '') {
			setManualValue(valueRange);
			return;
		}
		if (/^\d*(\.?\d?)?$/.test(manualValue) && !isVarInteger) {
			const value = Number(manualValue).toFixed(1);
			setManualValue(value);
			setValueRange(value);
			return;
		}
		if (/\.\d+?0+$/.test(manualValue) && !isVarInteger) {
			const value = manualValue.replace(/(\.\d+?)0+$/, '$1');
			setManualValue(value);
			setValueRange(value);
			return;
		}
		setValueRange(manualValue);
	};

	const handleRangeChange = (action: 'increase' | 'decrease') => {
		let value = '0';
		if (action === 'increase') {
			value = (+valueRange + step).toFixed(precision);
		}
		if (action === 'decrease') {
			value = (+valueRange - step).toFixed(precision);
		}
		value = value.replace(/(\.\d+?)0+$/, '$1');

		let result = value;
		if (+value > item.max) {
			result = String(item.max);
		}
		if (+value < item.min) {
			result = String(item.min);
		}
		setValueRange(result);
		setManualValue(result);
	};

	useEffect(() => {
		isFirstRun.current
			? (isFirstRun.current = false)
			: patchVariableBlock(item, valueRange, item.type);
	}, [values]);

	return varValue ? (
		<div style={{ minWidth: '95px' }}>
			<div className={styles.rangeWrapper}>
				<p className={styles.scaleValue}>{item?.min}</p>
				<div className={styles.range}>
					<p
						className={styles.rangeNum}
						style={{
							left: `${(+valueRange * 100) / item?.max}%`,
							transform: `translate(-${(+valueRange * 100) / item?.max}%, 0%)`,
						}}
					>{`
                ${valueRange} 
              ${
								item?.value_type === 'percent'
									? '%'
									: item?.value_type === 'celsius'
									? '°C'
									: item?.value_type === 'days'
									? 'days'
									: item?.value_type === 'hours'
									? 'hours'
									: item?.value_type === 'minutes'
									? 'min'
									: item?.value_type === 'seconds'
									? 'sec'
									: ''
							}
            `}</p>
					<input
						id={item.id.toString()}
						name="range"
						value={valueRange}
						onChange={changeRange}
						className={styles.rangeInput}
						style={{
							background: `linear-gradient(90deg, #60BF69 ${
								((+valueRange - item?.min) / (item?.max - item?.min)) * 100
							}%, #ADADAD ${
								((+valueRange - item?.min) / (item?.max - item?.min)) * 100
							}%)`,
						}}
						type="range"
						min={item?.min}
						max={item?.max}
						step={step}
						// defaultValue={valueRange}
					/>
				</div>
				<p className={styles.scaleValue}>{item?.max}</p>
			</div>
			<p className={styles.description}>{item?.description}</p>

			<form
				className={styles.adjustmentForm}
				noValidate
				onSubmit={onManualInputSubmit}
			>
				<button
					type="button"
					className={styles.adjustment}
					onClick={() => handleRangeChange('decrease')}
				>
					<img src={minus} alt="убавить" />
				</button>
				<input
					type="text"
					name="text"
					className={styles.adjustmentInput}
					value={manualValue}
					onChange={changeRange}
				/>
				<button
					type="button"
					className={styles.adjustment}
					onClick={() => handleRangeChange('increase')}
				>
					<img src={plus} alt="добавить" />
				</button>
			</form>
			<OnlineStatus isOnline={item.variable.is_online_device} />
		</div>
	) : (
		<NoVariable />
	);
};

export default Range;
