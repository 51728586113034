import React, { FC } from "react";

type ArrowType = {
  disclosure: boolean
  handleArrow: (e) => void
}

const Arrow: FC<ArrowType> = ({ disclosure, handleArrow }) => {
  // console.log(disclosure)
  return (
    <svg onClick={handleArrow}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{
        cursor: "pointer",
        padding: 5,
        boxSizing: "border-box",
        transform: disclosure ? "rotate(180deg)" : "rotate(0deg)",
        transitionDuration: ".4s",
      }}
      xmlns="http://www.w3.org/2000/svg">
      <path d="M6 9L12 15L18 9" stroke="#ECECEC" strokeWidth="1.54" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  )
}

export default Arrow;