import { initStateType } from '../../reducer';
import produce from 'immer';
import { setCurrentZoneType } from '../../actions/realty/setCurrentZone';

export const setCurrentZoneReducer = (
	state: initStateType,
	action: setCurrentZoneType,
) =>
	produce(state, (draft) => {
		draft.currentZone = action.payload.zone_id;
	});
