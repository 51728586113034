import {MESSAGE_ACTION} from "../../actionTypes";

export type setMessageType = {
  type: typeof MESSAGE_ACTION,
  payload: string,
}

export const setMessage = (data: setMessageType) => ({
  type: MESSAGE_ACTION,
  payload: data,
})