import React, { FC } from 'react';
import styles from './onOff.module.scss';
import { offSvg, onSvg } from './images';
import { OnOffBlockType } from '../../../../../../types/cardTypes';
import NoVariable from '../no-variable/no-variable';
import OnlineStatus from '../online-status/online-status';

type OnOffType = {
	item: OnOffBlockType;
	patchVariableBlock: (block, value, type) => void;
};

const OnOff: FC<OnOffType> = ({ item, patchVariableBlock }) => {
	const handleOnOff = () => {
		if (Number(item.variable.value) > 0 || item.variable.value === true) {
			patchVariableBlock(item, '0', item.type);
		} else {
			patchVariableBlock(item, '1', item.type);
		}
	};

	return item.variable ? (
		<div className={styles.onOffWrapper}>
			<div className={styles.item} onClick={handleOnOff}>
				<img
					src={
						Number(item.variable.value) > 0 || item.variable.value === true
							? onSvg
							: offSvg
					}
					alt="onOff"
				/>
				<p className={styles.value}>
					{Number(item.variable.value) > 0 || item.variable.value === true
						? 'On'
						: 'Off'}
				</p>
			</div>
			<div className={styles.description}>
				{item.description}
				<OnlineStatus isOnline={item.variable.is_online_device} />
			</div>
		</div>
	) : (
		<NoVariable />
	);
};

export default OnOff;
