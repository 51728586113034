export const Email = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6667 3.33337H3.33333C2.59695 3.33337 2 3.93033 2 4.66671V11.3334C2 12.0698 2.59695 12.6667 3.33333 12.6667H12.6667C13.403 12.6667 14 12.0698 14 11.3334V4.66671C14 3.93033 13.403 3.33337 12.6667 3.33337Z"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 4.66663L8 8.66663L14 4.66663"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
