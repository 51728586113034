import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connector } from "../../container";
import smartHouse from "../../images/smartHouse.png";
import styles from "./greetingPage.module.scss";


const GreetingPage = (props) => {
  const history = useHistory();

  const { authorization } = props;
  const { user, image } = authorization;
  const username = user.full_name;

  const date = new Date();
  const hour = date.getHours();

  const greetingFromHour = (hour: number) => {
    if (hour >= 6 && hour < 12) {
      return `Доброе утро, ${username || 'Пользователь'}!`;
    } else if (hour >= 12 && hour < 18) {
      return `Добрый день, ${username || 'Пользователь'}!`;
    } else if (hour >= 18 && hour <= 24) {
      return `Добрый вечер, ${username || 'Пользователь'}!`;
    } else if (hour >= 0 && hour < 6) {
      return `Доброй ночи, ${username || 'Пользователь'}!`;
    }
  };

  const greetingTitle = greetingFromHour(hour);

  useEffect(() => {
    setTimeout(() => {
      history.push("/");
    }, 4000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSide}>
        <h2 className={styles.greetingTitleLeft}>{greetingTitle}</h2>
      </div>

      <div className={styles.rightSide}>
        <img
          src={image ? image : smartHouse}
          alt="Умный дом"
          className={styles.mainImage}
        />
        <h2 className={styles.greetingTitleRight}>{greetingTitle}</h2>
      </div>
    </div>
  );
};

export default connector(GreetingPage);
