import { SET_CURRENT_REALTY } from '../../actionTypes';

export type setCurrentRealtyType = {
	type: typeof SET_CURRENT_REALTY;
	payload: {
		realty_id: number;
	};
};

export const setCurrentRealty = (data) => ({
	type: data.type,
	payload: data.payload,
});
