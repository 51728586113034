import React, {FC, useEffect, useState} from "react";
import styles from './cards-panel.module.scss';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Card from "./card/cards";
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../store/root-reducer";
import {CardType} from "../../types/cardTypes";
import {ZoneType} from "../../types/types";
import {v4 as uuidv4} from 'uuid';
import {getZonesCard, getZonesCardType} from "../../pages/home/redux-sagas/actions/realty/getZonesCard";
import {GET_ZONES_CARD_ACTION, PATCH_CARDS_SAGA} from "../../pages/home/redux-sagas/actionTypes";
import {PatchCardAsyncType} from "../../pages/home/redux-sagas/sagas/poll/patchCardAsync";
import Preloader from "../preloader/preloader";

type CardsPanelType = {
  cards: CardType[]
  patchVariableBlock: (block, value, type) => void
}

const CardsPanel: FC<CardsPanelType> = ({patchVariableBlock}) => {
  const dispatch = useDispatch()
  const cards = useSelector((state: appGlobalStore) => state.homePage.cards)
  const dndCards = useSelector((state: appGlobalStore) => state.homePage.dndCards)
  const zones: ZoneType[] = useSelector(({homePage}: appGlobalStore) => homePage.zones)

  const itemsFromBackend = [
    {id: uuidv4(), content: "First task"},
    {id: uuidv4(), content: "Second task"},
    {id: uuidv4(), content: "Third task"},
    {id: uuidv4(), content: "Fourth task"},
    {id: uuidv4(), content: "Fifth task"}
  ];


  useEffect(() => {
    const columnsFromBackend = {
      "id-1": {
        items: cards
      },
      "id-2": {
        items: []
      },
      "id-3": {
        items: []
      },
    };
    dndCards && setColumns(dndCards)
  }, [cards || cards.length !== 0])

  const [columns, setColumns] = useState(dndCards)

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const {source, destination} = result;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      const findRemovedIndex = destItems.findIndex((card: CardType, index: number) => card.id === removed.id) + 1
      const newColumn = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems
        }
      }
      setColumns(newColumn);
      addZonesCardAction(newColumn)
      changingOrderId(result, findRemovedIndex)
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      const findRemovedIndex = copiedItems.findIndex((card: CardType, index: number) => card.id === removed.id) + 1
      const newColumn = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems
        }
      }
      setColumns(newColumn);
      addZonesCardAction(newColumn)
      changingOrderId(result, findRemovedIndex)
    }
  };

  const addZonesCardAction = (obj) => {
    const zonesCardAction: getZonesCardType = {
      type: GET_ZONES_CARD_ACTION,
      payload: {cards: obj},
    }
    dispatch(getZonesCard(zonesCardAction))
  }


  const changingOrderId = (result, order_id) => {
    const cardId = Number(result.draggableId)
    const newColumn = {
      column_id: Number(result.destination.droppableId.slice(-1)),
      order_id: order_id,
    }
    const zoneId = cards.find((card: CardType) => card.id === cardId).zone_id
    const realtyId = zones.find((zone: ZoneType) => zone.id === zoneId).realty_id
    const patchCardSaga: PatchCardAsyncType = {
      type: PATCH_CARDS_SAGA,
      payload: {
        card_id: cardId,
        zone_id: zoneId,
        realty_id: realtyId,
        variables: newColumn,
      }
    }
    dispatch(patchCardSaga)
  }


  return (
    <main className={styles.cardsPanel}>
      {
        Object.keys(columns).length === 0 ? (
          <Preloader/>
        ) : (
          cards.length === 0 ? (
            <p className={styles.empty}>Карточки не добавлены администратором</p>
            ) : (
            <DragDropContext
              onDragEnd={result => onDragEnd(result, columns, setColumns)}
            >
              {Object.entries(columns).map(([columnId, column], index) => {
                return (
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          key={columnId}
                          style={{
                            background: snapshot.isDraggingOver ? "#1c1c1c" : "#1A1A1A",
                            width: 430,
                            minHeight: 150,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                          }}
                        >
                          {/*//@ts-ignore*/}
                          {column.items.map((item: CardType, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id_str}
                                index={index}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      {...provided.draggableProps}
                                      ref={provided.innerRef}
                                    >
                                      <Card card={item} patchVariableBlock={patchVariableBlock} key={item.id}
                                            provided={provided}/>
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                );
              })}
            </DragDropContext>
            )

        )
      }

    </main>
  );


  // return (
  //   <main className={styles.cardsPanel}>
  //     {
  //       cards?.map((item: CardType) => <Card card={item} patchVariableBlock={patchVariableBlock} key={item.id}/>)
  //     }
  //   </main>
  // )
}

export default CardsPanel;