import React, { FC } from 'react';
import './climate.module.scss';
import styles from './climate.module.scss';
import { ClimateItemType } from '../../../../../../types/cardTypes';
import { AirBlowOff, ColdSvg, DehumidificationSvg, HeatSvg } from './images';
import OnlineStatus from '../online-status/online-status';

type BlockVariableType = {
	handleClimate: (id) => void;
	item: ClimateItemType;
	index: number;
};

export const BlockVariable: FC<BlockVariableType> = ({
	handleClimate,
	item,
}) => {
	const handleIsActive = (e: React.MouseEvent<HTMLLIElement>) => {
		e.stopPropagation();
		e.preventDefault();
		handleClimate(item);
	};

	const renderIcon = (type: ClimateItemType['type']) => {
		switch (type) {
			case 'dehumidification':
			case 'Осушение':
				return <DehumidificationSvg item={item} />;
			case 'Продувка':
			case 'air-blow-off':
				return <AirBlowOff item={item} />;
			case 'Холод':
			case 'cold':
				return <ColdSvg item={item} />;
			case 'Тепло':
			case 'heat':
				return <HeatSvg item={item} />;
		}
	};

	return (
		<li className={styles.blockVariableClimate} onClick={handleIsActive}>
			{renderIcon(item.type || item.description)}
			<p
				className={styles.description}
				style={{
					color:
						Number(item.value) > 0 || item.value === true
							? '#FFFFFF'
							: '#ADADAD',
				}}
			>
				{item.description || item.type}
			</p>
			<div className={styles.description}>
				<OnlineStatus isOnline={item.is_online_device} />
			</div>
		</li>
	);
};
