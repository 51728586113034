import styles from './color.module.scss';
import React, {
	createRef,
	FC,
	FunctionComponent,
	MutableRefObject,
	RefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import iro from '@jaames/iro';
import { ColorBlockType } from '../../../../../../types/cardTypes';
import { useDebounce } from 'use-debounce';
import NoVariable from '../no-variable/no-variable';
import OnlineStatus from '../online-status/online-status';

interface IroColorPicker {
	item: ColorBlockType;
	patchVariableBlock: (item: ColorBlockType, value: string, type) => void;
	on?: (action: string, callback: Function) => void;
	color?: {
		hexString: string;
		set: (value: any) => void;
		setState: (state: any) => void;
	};
}

const Color: FC<IroColorPicker> = ({ item, patchVariableBlock }) => {
	const value =
		item.variable.value !== '#000000' ? item.variable.value : '#ffffff';
	const [color, setColor] = useState(item.variable.value);
	const [colorValues] = useDebounce(color, 1000);

	const isFirstRun = useRef(true);
	useEffect(() => {
		isFirstRun.current
			? (isFirstRun.current = false)
			: patchVariableBlock(item, colorValues, item.type);
	}, [colorValues]);
	//@ts-ignore
	let colorPicker: MutableRefObject<IroColorPicker | null> =
		useRef<IroColorPicker | null>(null);
	let el: RefObject<HTMLDivElement> = createRef<HTMLDivElement>();

	useEffect(() => {
		if (!el.current) {
			return;
		}
		if (!colorPicker.current) {
			//@ts-ignore
			colorPicker.current = new iro.ColorPicker(el.current, {
				width: 100,
				color: value,
				handleSvg: '#handle',
				// handleRadius: 10,
				layout: [
					{
						component: iro.ui.Wheel,
					},
					{
						component: iro.ui.Slider,
					},
				],
			});
			if (!colorPicker.current) {
				return;
			}
			colorPicker.current.on('color:change', (color: { hexString: string }) => {
				handleHex(color.hexString);
			});
		}
		// else if (value !== colorPicker.current.color.hexString) {
		//   colorPicker.current.color.set(value);
		// }
	});

	const handleHex = (color) => {
		setColor(color);
		//console.log(color)
	};

	return item.variable ? (
		<div className={styles.wrapper}>
			<div className={styles.pickerWrapper} ref={el} />
			<svg
				className="IroHandle IroHandle--0 IroHandle--isActive"
				style={{
					display: 'none',
					transform: 'translate(31.0205px, 63.7894px)',
					willChange: 'transform; top: -10px',
					left: '-10px',
					width: '20px',
					height: '20px',
					position: 'absolute',
					overflow: 'visible',
					cursor: 'pointer',
				}}
			>
				<g id="handle">
					<circle
						cx="10"
						cy="10"
						r="6"
						fill="hsl(236, 100%, 66%, 0.1)"
						strokeWidth="2"
						stroke="#fff"
					/>
				</g>
			</svg>
			<OnlineStatus isOnline={item.variable.is_online_device} />
		</div>
	) : (
		<NoVariable />
	);
};

export default Color;
