import {call, put, takeEvery} from "redux-saga/effects";
import {PATCH_CARDS_SAGA} from "../../actionTypes";
import {api} from "../../../../../api/restClient";

export type PatchCardAsyncType = {
  type: typeof PATCH_CARDS_SAGA,
  payload: {
    card_id: number,
    zone_id: number,
    realty_id: number,
    variables: object,
  }
}

const patchApiCard = (patchObj: { realty_id: number, zone_id: number, card_id: number, variable: object }) => {
  return api.patch(`realty/${patchObj.realty_id}/zone/${patchObj.zone_id}/card/${patchObj.card_id}/`, patchObj.variable)
    .catch((err) => console.log(err))
}


function* patchCardAsync(action: PatchCardAsyncType) {
  try {
    const patchObj = yield {
      realty_id: action.payload.realty_id,
      zone_id: action.payload.zone_id,
      card_id: action.payload.card_id,
      variable: action.payload.variables,
    }
    const response = yield call(patchApiCard, patchObj)
  } catch (err) {
    console.log(err)
  }
}

export function* patchCardSagas() {
  yield takeEvery(PATCH_CARDS_SAGA, patchCardAsync)
}