import React from "react";
import './preloader.scss';


const Preloader = () => {

  return (
    <div className="banter-loader">
      <div className="banter-loader__box"/>
      <div className="banter-loader__box"/>
      <div className="banter-loader__box"/>
      <div className="banter-loader__box"/>
      <div className="banter-loader__box"/>
      <div className="banter-loader__box"/>
      <div className="banter-loader__box"/>
      <div className="banter-loader__box"/>
      <div className="banter-loader__box"/>
    </div>
  )
}

export default Preloader;