import React from 'react';
import '../styles/global.scss'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { store } from '../store/store'
import Router from './router'

function App() {
  return (
      <Provider store={store}>
          <BrowserRouter>
              <Router />
          </BrowserRouter>
      </Provider>
  );
}

export default App;
