import React, { FC } from 'react';
import styles from './climate.module.scss';
import {
	BlockType,
	ClimateBlockType,
	ClimateItemType,
} from '../../../../../../types/cardTypes';
import { BlockVariable } from './block-variable';

type ClimateType = {
	item: ClimateBlockType;
	patchVariableBlock: (block: BlockType, value: ClimateItemType, type) => void;
};

const Climate: FC<ClimateType> = ({ item, patchVariableBlock }) => {
	const { items } = item;

	const handleClimate = (climateVariable: ClimateItemType) => {
		patchVariableBlock(item, climateVariable, item.type);
	};

	return (
		<ul className={styles.wrapperClimate}>
			{items.map((obj: ClimateItemType, index) => {
				return (
					<BlockVariable
						key={`${index}${obj.id}`}
						handleClimate={handleClimate}
						item={obj}
						index={index}
					/>
				);
			})}
		</ul>
	);
};

export default Climate;
