import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as actions from "./redux-sagas/actions";
import * as selectors from "./redux-sagas/selectors";

import * as homeActions from "../../pages/home/redux-sagas/actions"
import * as homeSelectors from "../../pages/home/redux-sagas/selectors"

const mapStateToProps = createStructuredSelector({
  authorization: selectors.authPageStateSelector,
  message: homeSelectors.messageSelector
});

const mapDispatchToProps = {
  loggedIn: actions.loggedIn,
  loggedOut: actions.loggedOut,
  setEmail: actions.setEmailForRestorePassword,
  setLoadingTrue: actions.setLoadingTrue,
  setLoadingFalse: actions.setLoadingFalse,
  showError: actions.showError,
  setImageAndPhone: actions.setImageAndPhone,
  setFullUser: actions.setUser,
  setMessage: homeActions.setMessage
};

export const connector = connect(mapStateToProps, mapDispatchToProps);