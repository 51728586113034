export function EyeOff() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L14 14"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05598 7.05798C6.80579 7.30799 6.66516 7.64715 6.66504 8.00085C6.66491 8.35454 6.8053 8.6938 7.05531 8.94398C7.30532 9.19417 7.64448 9.3348 7.99817 9.33492C8.35186 9.33505 8.69112 9.19466 8.94131 8.94465"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5713 11.5661C10.4839 12.2994 9.29459 12.6667 7.99992 12.6667C5.33325 12.6667 3.11125 11.1114 1.33325 8.00008C2.24592 6.40341 3.27525 5.21675 4.42125 4.43941M6.24192 3.57675C6.81353 3.41323 7.40538 3.33131 7.99992 3.33341C10.6666 3.33341 12.8886 4.88875 14.6666 8.00008C14.1479 8.90741 13.5919 9.68275 12.9979 10.3254L6.24192 3.57675Z"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
