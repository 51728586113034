import React, { FC, useState, useEffect } from "react";
import { RealtyType} from "types/types";
import { useWindowSize } from "utils/useDimensions";
import styles from "./property.module.scss";

type propertyType = {
  activeRealty: number,
  realty: RealtyType[],
  handleRealty: (realty_id: number, realtyType, index: number) => void,
}

const setColorStyle = (currentIndex, activeItemIndex) => {
  const { colorGrayLight, colorGrayMedium } = styles;
  const color = currentIndex === activeItemIndex ? colorGrayLight : colorGrayMedium;
  return { color };
};

const InfoComponent = (props) => {
  return (
    <p className={props.className} style={props.style || {}}>
      {props.children}
    </p>
  );
};

const PropertyHorizontalList = (props) => {
  const {realty, activeRealty, handleRealty} = props;
  return (
    realty.map((item: RealtyType, index: number) => {
      const colorStyle = setColorStyle(activeRealty, index);
      return (
        <article className={styles.property} key={item.id} 
          title={ item.name + '\n' + item.addres}
          onClick={_ => handleRealty(item.id, item.name, index)}
        >
          <InfoComponent className={styles.property__name} style={colorStyle}>
            {item.name} 
          </InfoComponent>
          <InfoComponent className={styles.property__address} style={colorStyle}>
            {item.addres} 
          </InfoComponent>
          <div className={styles.property__activeSign}
            style={{ opacity: activeRealty === index ? 1 : 0 }}
          />
        </article>
      )
    })
  );
};

const PropertyVerticalList = (props) => {
  const {realty, activeRealty, handleRealty, onClick} = props;

  const handleClick = (id, name, index) => {
    handleRealty(id, name, index);
    onClick();
  };

  return (
    realty.map((item: RealtyType, index: number) => {
      const colorStyle = setColorStyle(activeRealty, index);
      return (
        <article key={item.id}
          className={styles.option} 
          onClick={_ => handleClick(item.id, item.name, index)}
        >
          <div className={styles.option__info}>
            <InfoComponent className={styles.option__name} style={colorStyle}>
              {item.name || ''} 
            </InfoComponent>
            <InfoComponent className={styles.option__address} style={colorStyle}>
              {item.addres || ''} 
            </InfoComponent>
          </div>
          <div className={styles.option__activeSign}
            style={{ opacity: activeRealty === index ? 1 : 0 }}
          />
        </article>
      )
    })
  );
};

const PropertyDropDown = (props) => {
  const {data, onClick} = props;
  const colorStyle = setColorStyle(1, 1);

  if (data) return (
    <article key={data.id} className={styles.dropDown}>
      <div className={styles.dropDown__info}>
        <InfoComponent className={styles.dropDown__name} style={colorStyle}>
          {data.name} 
        </InfoComponent>
        <InfoComponent className={styles.dropDown__address} style={colorStyle}>
          {data.addres} 
        </InfoComponent>
      </div>
      <button className={styles.dropDown__button} onClick={onClick}/>
    </article>
  );
  return (<React.Fragment />);
};

const Property: FC<propertyType> = ({ realty, activeRealty, handleRealty }) => {
  const [isWideScreen, setIsWideScreen] = useState(true);
  const [isUnfolded, setIsUnfolded] = useState(false);
  const size = useWindowSize();

  useEffect(() => {
    setIsWideScreen(size.width > parseInt(styles.changeHeaderWidth));
  }, [size.width]);

  const handleButtonClick = () => {
    setIsUnfolded(true);
  }

  const handleListClick = () => {
    setIsUnfolded(false);
  };

  const getActiveRealty = (realtyArray, activeRealtyIndex) => {
    return realtyArray.find((_, index: number) => (index === activeRealtyIndex));
  }

  return (
    <React.Fragment>
      { isWideScreen ?
        <div className={styles.propertyList}>
          <PropertyHorizontalList 
            realty={realty}
            activeRealty={activeRealty}
            handleRealty={handleRealty} 
          />
        </div> : 
          !isUnfolded ? 
          <PropertyDropDown 
            data={getActiveRealty(realty, activeRealty)} 
            onClick={handleButtonClick} 
          /> :
          <div className={styles.propertyVertical}>
            <PropertyVerticalList  
              realty={realty} 
              activeRealty={activeRealty} 
              handleRealty={handleRealty} 
              onClick={handleListClick} 
            />
          </div>
        }
    </React.Fragment>
  );

};

export default Property;