import React from 'react';
import styles from './error-state.module.scss';
import { ErrorStateBlockType } from 'types/cardTypes';
import NoVariable from '../no-variable/no-variable';
import { StatusIcon } from '../status-icon/status-icon';
import OnlineStatus from '../online-status/online-status';

interface IErrorStateIndicator {
	item: ErrorStateBlockType;
}

const ErrorStateIndicator = ({ item }: IErrorStateIndicator) => {
	if (!item) {
		return <NoVariable />;
	}

	const activeError = item.items[0].conditions.find((it) => it.condition_value === item.items[0].value)

		if (activeError) {
			return (
				<>
					<div className={styles.errorWrapper}>
						<div className={styles.value}>
							<StatusIcon colorOfStatus={activeError.color} />
							{activeError.text}
						</div>
						<div className={styles.description}>
							{item.description}
							<OnlineStatus isOnline={item.items[0].is_online_device} />
						</div>
					</div>
				</>
			);
		} else {
			return <></>;
		}
	}

export default ErrorStateIndicator;
