import { call, takeEvery, put, delay, select } from 'redux-saga/effects';
import {
	CLEAR_POPUP_NOTIFICATION_ACTION,
	GET_ZONES_CARDS_SAGA,
	GET_ZONES_CARD_ACTION,
	PATCH_CLIMATE_VARIABLE_CARD_SAGA,
	PATCH_CLIMATE_VARIABLE_CARD_SAGA_FAILED,
	PATCH_CLIMATE_VARIABLE_CARD_SAGA_SUCCEEDED,
	PATCH_VARIABLE_CARDS_SAGA,
	PUT_SCRIPT_REQUEST_FAILED,
	PUT_SCRIPT_REQUEST_SUCCEEDED,
	SET_POPUP_NOTIFICATION_ACTION,
} from '../../actionTypes';
import { api } from '../../../../../api/restClient';
import { BlockType } from '../../../../../types/cardTypes';
import { generateUUID } from 'utils/generateUUID';
import { popupStatusTypes } from './types';
import { getZonesCard } from '../../actions';
export type PatchZonesCardSagaType = {
	type:
		| typeof PATCH_VARIABLE_CARDS_SAGA
		| typeof PATCH_CLIMATE_VARIABLE_CARD_SAGA;
	payload: {
		value: string | boolean;
		id: number | string;
		type: BlockType['type'];
		device_id?: string;
		code?: string;
	};
};

const patchApiClimateVariableCard = (patchObj: {
	value: string;
	id: number;
}) => {
	return api
		.patch(`climate_variable/${patchObj.id}/`, { value: patchObj.value })
		.catch((err) => console.log(err));
};

const patchApiCardVariable = (patchObj: { value: string; id: number }) => {
	return api
		.patch(`variable/${patchObj.id}/`, {
			value: patchObj.value,
		})
		.catch((err) => console.log(err));
};

const patchApiBoolVariable = (patchObj: { value: string; id: number }) => {
	return api
		.patch(`bool_variable/${patchObj.id}/`, {
			value: patchObj.value,
		})
		.catch((err) => console.log(err));
};

const putApiScriptBlock = (putObj: {
	value: string;
	code: string;
	device_id: string;
}) => {
	return api.put(`script_variable/update_parameter_value/`, [
		{
			code: putObj.code,
			device_id: putObj.device_id,
			value: putObj.value,
		},
	]);
};

const getApiZonesCards = (obj: { realty_id: number; zones_id: number }) => {
	return api
		.get(`realty/${obj.realty_id}/zone/${obj.zones_id}/card/`)
		.then((res) => res.data)
		.catch((err) => console.log(err));
};

function* patchZonesCardAsync(action: PatchZonesCardSagaType) {
	const uid = generateUUID();
	const patchObj = yield {
		value: action.payload.value,
		id: action.payload.id,
		device_id: action.payload.device_id,
		code: action.payload.code,
	};
	const currentZone = yield select((state) => state.homePage.currentZone);
	const currentRealty = yield select((state) => state.homePage.currentRealty);

	switch (action.payload.type) {
		case 'climate':
			try {
				yield call(patchApiClimateVariableCard, patchObj);
				const response = yield call(getApiZonesCards, {
					realty_id: currentRealty,
					zones_id: currentZone,
				});

				yield put(
					getZonesCard({
						type: GET_ZONES_CARD_ACTION,
						payload: { cards: response },
					}),
				);

				yield put({
					type: SET_POPUP_NOTIFICATION_ACTION,
					payload: {
						message: 'Параметр успешно изменен',
						id: uid,
						type: popupStatusTypes.SUCCESS,
					},
				});

				yield delay(5000);
				yield put({
					type: CLEAR_POPUP_NOTIFICATION_ACTION,
					payload: { id: uid },
				});
			} catch (err) {
				yield put({
					type: SET_POPUP_NOTIFICATION_ACTION,
					payload: {
						message: `Ошибка в изменении климата\n ${err.message}`,
						id: uid,
						type: popupStatusTypes.ERROR,
					},
				});
				yield delay(5000);
				yield put({
					type: CLEAR_POPUP_NOTIFICATION_ACTION,
					payload: { id: uid },
				});
				console.log(err);
			}
			break;
		case 'boolean_indicator':
			try {
				yield call(patchApiBoolVariable, patchObj);
				const response = yield call(getApiZonesCards, {
					realty_id: currentRealty,
					zones_id: currentZone,
				});

				yield put(
					getZonesCard({
						type: GET_ZONES_CARD_ACTION,
						payload: { cards: response },
					}),
				);
			} catch (err) {
				console.log(err);
			}
			break;
		case 'script':
			try {
				yield call(putApiScriptBlock, patchObj);

				const response = yield call(getApiZonesCards, {
					realty_id: currentRealty,
					zones_id: currentZone,
				});

				yield put(
					getZonesCard({
						type: GET_ZONES_CARD_ACTION,
						payload: { cards: response },
					}),
				);

				yield put({
					type: SET_POPUP_NOTIFICATION_ACTION,
					payload: {
						message: 'Параметр успешно изменен',
						id: uid,
						type: popupStatusTypes.SUCCESS,
					},
				});

				yield put({
					type: SET_POPUP_NOTIFICATION_ACTION,
					payload: {
						message: `Состояние устройства ${patchObj.device_id} успешно изменено`,
						id: uid,
						type: popupStatusTypes.SUCCESS,
					},
				});
				yield delay(5000);
				yield put({
					type: CLEAR_POPUP_NOTIFICATION_ACTION,
					payload: { id: uid },
				});
			} catch (err) {
				yield put({
					type: SET_POPUP_NOTIFICATION_ACTION,
					payload: {
						message: `Ошибка при изменении состояния устройства ${patchObj.device_id}\n ${err.message}`,
						id: uid,
						type: popupStatusTypes.ERROR,
					},
				});
				console.log(err);
				yield delay(5000);
				yield put({
					type: CLEAR_POPUP_NOTIFICATION_ACTION,
					payload: { id: uid },
				});
			}
			break;
		default:
			try {
				yield call(patchApiCardVariable, patchObj);

				const response = yield call(getApiZonesCards, {
					realty_id: currentRealty,
					zones_id: currentZone,
				});

				yield put(
					getZonesCard({
						type: GET_ZONES_CARD_ACTION,
						payload: { cards: response },
					}),
				);

				yield put({
					type: SET_POPUP_NOTIFICATION_ACTION,
					payload: {
						message: 'Параметр успешно изменен',
						id: uid,
						type: popupStatusTypes.SUCCESS,
					},
				});

				yield delay(5000);
				yield put({
					type: CLEAR_POPUP_NOTIFICATION_ACTION,
					payload: { id: uid },
				});
			} catch (err) {
				yield put({
					type: SET_POPUP_NOTIFICATION_ACTION,
					payload: {
						message: `Ошибка: ${err.message}`,
						id: uid,
						type: popupStatusTypes.ERROR,
					},
				});
				console.log(err);
				yield delay(5000);
				yield put({
					type: CLEAR_POPUP_NOTIFICATION_ACTION,
					payload: { id: uid },
				});
			}
	}
}

export function* patchZonesCardSagas() {
	yield takeEvery(PATCH_VARIABLE_CARDS_SAGA, patchZonesCardAsync);
	yield takeEvery(PATCH_CLIMATE_VARIABLE_CARD_SAGA, patchZonesCardAsync);
}
