import React from 'react'
import {  ScriptBlockType } from 'types/cardTypes'
import Button from 'components/button/button'
import styles from './script-block.module.scss'
import QuestionMark from 'components/question-mark/question-mark'

interface IScriptBlock {
  item: ScriptBlockType
  patchVariableBlock: (block: ScriptBlockType, value, type) => void
}

const ScriptBlock = ({item, patchVariableBlock}: IScriptBlock) => {

  const {button_label, description, detailed_description} = item

  const handlePatchScriptBlock = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    item.items.forEach(el => {
      patchVariableBlock(item, el, item.type)
    })
  }

  return (
   <div className={styles.blockContainer}>
    <p className={styles.blockDescription}>{description}</p>

    <QuestionMark tooltipText={detailed_description} fillColor='#FFF'/>

    <Button clickHandler={handlePatchScriptBlock} text={button_label}/>
   </div>
  )
}

export default ScriptBlock