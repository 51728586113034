import styles from './inputs.module.scss'
import * as Image from "../../images";

export type Props = {
  errorText: string;
  onChange: any;
};

export const InputEmail = (props: Props) => {
  const { onChange, errorText } = props;

  return (
    <>
      <div className={styles.inputWrapper}>
        <div className={styles.icon}>
          <Image.Email />
        </div>
        <input
          name="email"
          type="email"
          className={styles.input}
          placeholder="E-mail"
          onChange={onChange}
          required
          minLength={5}
        />
      </div>
      <span className={styles.error}>{errorText}</span>
    </>
  );
};
