import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appGlobalStore } from '../../store/root-reducer';
import {
	CLEAR_POPUP_NOTIFICATION_ACTION,
	GET_NOTIFICATION_SAGA,
	GET_REALTY_SAGA,
	GET_ZONES_CARD_ACTION,
	GET_ZONES_SAGA,
	NOTIFICATION_ACTION,
	PATCH_CLIMATE_VARIABLE_CARD_SAGA,
	PATCH_NOTIFICATION_SAGA,
	PATCH_VARIABLE_CARDS_SAGA,
	SET_CURRENT_REALTY,
	TOGGLE_NOTIFICATION_ACTION,
} from './redux-sagas/actionTypes';
import { NotificationType, RealtyType } from '../../types/types';
import { GetZonesType } from './redux-sagas/actions/realty/getZones';
import {
	BlockType,
	CardType,
	ClimateBlockType,
	ClimateItemType,
	ContentCardType,
	ScriptBlockType,
	ScriptBlockTypeItem,
} from '../../types/cardTypes';
import {
	getZonesCard,
	getZonesCardType,
} from './redux-sagas/actions/realty/getZonesCard';
import {
	toggleNotification,
	toggleNotificationType,
} from './redux-sagas/actions/realty/toggleNotification';
import {
	notificationAction,
	NotificationActionType,
} from './redux-sagas/actions/realty/notificationAction';
import { PatchNotificationAsyncType } from './redux-sagas/sagas/poll/patchNotificationAsync';
import { PatchZonesCardSagaType } from './redux-sagas/sagas/poll/patchZonesCardAsync';
import { useWindowDimensions } from 'utils/useDimensions';
import Header from 'components/header/header';
import SideLeftPanel from 'components/side-left-panel/side-left-panel';
import CardsPanel from 'components/cards-panel/cards-panel';
import ModalOverlay from 'modal/modal-overlay';
import ModalNotice from 'modal/modal-notice/modal-notice';
import ModalMobile from 'modal/modal-mobile/modal-mobile';
import styles from './home.module.scss';
import Popup from 'components/popup/popup';

export const HomePage = () => {
	const dispatch = useDispatch();
	const loggedIn = useSelector(
		({ authorization }: appGlobalStore) => authorization.loggedIn,
	);
	const realty: RealtyType[] = useSelector(
		({ homePage }: appGlobalStore): RealtyType[] => homePage.realty,
	);
	const cards = useSelector((state: appGlobalStore) => state.homePage.cards);
	const dndCards = useSelector(
		(state: appGlobalStore) => state.homePage.dndCards,
	);
	const activeNotification = useSelector(
		(state: appGlobalStore) => state.homePage.activeNotification,
	);
	const notification = useSelector(
		(state: appGlobalStore) => state.homePage.notification,
	);
	const popupNotifications = useSelector(
		(state: appGlobalStore) => state.homePage.popupNotifications,
	);

	const [activeRealty, setActiveRealty] = useState(0);
	const [mouseNotification, setMouseNotification] = useState<{
		item: NotificationType;
		is_read: boolean;
	}>(null);
	const [notificationObj, setNotificationObj] = useState({
		item: { created_at: null, id: null, is_read: null, massage: null },
		is_read: null,
	});
	const RefModalNotice = useRef();
	const [isWideScreen, setIsWideScreen] = useState(true);
	const { width } = useWindowDimensions();

	const deletePopupNotification = React.useCallback(
		(id: string) => {
			dispatch({
				type: CLEAR_POPUP_NOTIFICATION_ACTION,
				payload: {
					id,
				},
			});
		},
		[dispatch],
	);

	useEffect(() => {
		setIsWideScreen(width > parseInt(styles.changeHeaderWidth));
	}, [width]);

	useEffect(() => {
		loggedIn && dispatch({ type: GET_REALTY_SAGA });
		loggedIn && dispatch({ type: GET_NOTIFICATION_SAGA });
	}, [loggedIn]);

	useEffect(() => {
		if (realty.length) {
			dispatch({
				type: SET_CURRENT_REALTY,
				payload: { realty_id: realty[0].id },
			});
		}
	}, [realty]);

	const patchVariableBlock = (
		block: BlockType,
		value,
		type: BlockType['type'],
	) => {
		const newCards = cards?.map((item: CardType) => {
			const newContent = item?.content.map((content: ContentCardType) => {
				const newBlocks = content?.blocks?.map((el) => {
					if (block.id === el.id) {
						switch (type) {
							case 'climate':
								return {
									...el,
									//@ts-ignore
									items: block.items.map((climateBlock: ClimateBlockType) => {
										if (climateBlock.id === value.id)
											return { ...climateBlock, value: value.value };
										return { ...climateBlock, value: false };
									}),
								};
							case 'script':
								return {
									...el,
									//@ts-ignore
									items: block.items.map((scriptBlock: ScriptBlockType) => {
										if (scriptBlock.id === value.id)
											return { ...scriptBlock, value: value };
										return { ...scriptBlock, value: value };
									}),
								};
							case 'switch-toggle':
							case 'scale':
							case 'addition':
							case 'on-off':
							case 'color':
							case 'all-off':
							case 'error_indicator':
							case 'boolean_indicator':
								return {
									...el,
									//@ts-ignore
									variable: { ...el.variable, value: value },
								};
						}
					}
					return el;
				});

				return {
					...content,
					blocks: newBlocks,
				};
			});
			return {
				...item,
				content: newContent,
			};
		});

		const activeCard = newCards.find((card) => {
			const searchContent = (card) => {
				return card.content.find((content: ContentCardType) => {
					const findBlock: BlockType = content.blocks.find(
						(el: BlockType) => block?.id === el?.id,
					);
					if (block?.id === findBlock?.id) return content;
				})?.id;
			};

			return card.content.find((item) => item?.id === searchContent(card));
		});

		const newDnd = {
			...dndCards,
			[`id-${String(activeCard.column_id)}`]: {
				items: [
					dndCards[`id-${String(activeCard.column_id)}`].items.map(
						(card: CardType) => {
							return {
								...card,
							};
						},
					),
				],
			},
		};

		switch (type) {
			case 'climate': {
				const val: ClimateItemType = value;
				const realValue =
					Number(val.value) > 0 || val.value === true ? false : true;
				//@ts-ignore

				return dispatch<PatchZonesCardSagaType>({
					type: PATCH_CLIMATE_VARIABLE_CARD_SAGA,
					//@ts-ignore
					payload: { value: realValue, id: val.id, type },
				});
			}
			case 'script': {
				const val: ScriptBlockTypeItem = value;
				//@ts-ignore
				return dispatch<PatchZonesCardSagaType>({
					type: PATCH_CLIMATE_VARIABLE_CARD_SAGA,
					payload: {
						value: val.value,
						code: val.code,
						device_id: val.device_id,
						type,
						id: val.id,
					},
				});
			}
			default:
				//@ts-ignore
				return dispatch<PatchZonesCardSagaType>({
					type: PATCH_VARIABLE_CARDS_SAGA,
					//@ts-ignore
					payload: { value, id: block.variable.id, type },
				});
		}
	};

	const handleRealty = (realty_id, realtyType, index) => {
		setActiveRealty(index);
		const zonesCardAction: getZonesCardType = {
			type: GET_ZONES_CARD_ACTION,
			payload: { cards: {} },
		};
		dispatch(getZonesCard(zonesCardAction));
		const zonesAction: GetZonesType = {
			type: GET_ZONES_SAGA,
			payload: {
				realty_id: realty_id,
				zones: [],
			},
		};
		dispatch(zonesAction);
	};

	const handleNotification = (active) => {
		const toggleNotificationAction: toggleNotificationType = {
			type: TOGGLE_NOTIFICATION_ACTION,
			payload: {
				active: active,
			},
		};
		dispatch(toggleNotification(toggleNotificationAction));
	};

	const useDebounce = (value, delay): void => {
		const [debouncedValue, setDebouncedValue] = useState(value);
		useEffect(() => {
			const handler = setTimeout(() => {
				setDebouncedValue(value);
			}, delay);
			return () => {
				clearTimeout(handler);
			};
		}, [value, delay]);
		return debouncedValue;
	};
	const debouncedSearchTerm = useDebounce(mouseNotification, 1000);

	useEffect(() => {
		//@ts-ignore
		if (debouncedSearchTerm && mouseNotification) {
			const newNotification =
				Object.keys(notificationObj.item).length !== 0 &&
				notification.map((item: NotificationType) => {
					if (item?.id === notificationObj?.item?.id)
						return { ...item, is_read: notificationObj.is_read };
					return { ...item };
				});
			const variables = {
				is_read: notificationObj.is_read,
			};
			const newNotificationAction: NotificationActionType = {
				type: NOTIFICATION_ACTION,
				payload: {
					notifications: newNotification,
				},
			};
			dispatch(notificationAction(newNotificationAction));
			const patchNotificationSaga: PatchNotificationAsyncType = {
				type: PATCH_NOTIFICATION_SAGA,
				payload: {
					notification_id: notificationObj?.item?.id,
					variables,
				},
			};
			dispatch(patchNotificationSaga);
		}
	}, [debouncedSearchTerm]);

	useEffect(() => {
		if (isWideScreen === false) {
			const newNotification =
				Object.keys(notificationObj.item).length !== 0 &&
				notification.map((item: NotificationType) => {
					if (item?.id === notificationObj?.item?.id)
						return { ...item, is_read: notificationObj.is_read };
					return { ...item };
				});
			const variables = {
				is_read: notificationObj.is_read,
			};
			const newNotificationAction: NotificationActionType = {
				type: NOTIFICATION_ACTION,
				payload: {
					notifications: newNotification,
				},
			};
			dispatch(notificationAction(newNotificationAction));
			const patchNotificationSaga: PatchNotificationAsyncType = {
				type: PATCH_NOTIFICATION_SAGA,
				payload: {
					notification_id: notificationObj?.item?.id,
					variables,
				},
			};
			dispatch(patchNotificationSaga);
		}
	}, [notificationObj]);

	const mouseOverNotification = (
		e,
		card: NotificationType,
		isRead: boolean,
	) => {
		setMouseNotification(e.clientX);
		setNotificationObj({ item: card, is_read: isRead });
	};

	const mouseClickNotification = (
		message: NotificationType,
		isSeen: boolean,
	) => {
		setNotificationObj({ item: message, is_read: isSeen });
	};

	return (
		<div className={styles.main}>
			{isWideScreen ? (
				<>
					<SideLeftPanel />
					<div className={styles.container}>
						<Header
							activeRealty={activeRealty}
							realty={realty}
							notification={notification}
							handleRealty={handleRealty}
							handleNotification={handleNotification}
						/>
						<CardsPanel cards={cards} patchVariableBlock={patchVariableBlock} />
					</div>
				</>
			) : (
				<>
					<div className={styles.container}>
						<Header
							activeRealty={activeRealty}
							realty={realty}
							notification={notification}
							handleRealty={handleRealty}
							handleNotification={handleNotification}
						/>
						<SideLeftPanel />
						<CardsPanel cards={cards} patchVariableBlock={patchVariableBlock} />
					</div>
				</>
			)}
			{activeNotification && (
				<ModalOverlay
					handleNotification={handleNotification}
					RefModalNotice={RefModalNotice}
				>
					{isWideScreen ? (
						<ModalNotice
							notification={notification}
							handleNotification={handleNotification}
							// setMouseNotification={setMouseNotification}
							// mouseOverNotification={mouseOverNotification}
							mouseClickNotification={mouseOverNotification}
							RefModalNotice={RefModalNotice}
						/>
					) : (
						<ModalMobile
							notification={notification}
							handleNotification={handleNotification}
							RefModalNotice={RefModalNotice}
							mouseClickNotification={mouseClickNotification}
						/>
					)}
				</ModalOverlay>
			)}
			{popupNotifications && (
				<Popup
					deletePopupHandler={deletePopupNotification}
					notificationsArray={popupNotifications}
				/>
			)}
		</div>
	);
};
