import React, {FC} from "react";
import { useHistory } from "react-router-dom";
import styles from './logout.module.scss';
import {logoutSvg} from "../images/index";
import { connector } from "app/container";
import {useDispatch, useSelector} from "react-redux";
import {appGlobalStore} from "../../../store/root-reducer";

type LogoutType = {
  stateElem: boolean;
  loggedOut: () => void;
  setLoadingTrue: () => void;
};

const Logout: FC<LogoutType> = ({stateElem, loggedOut, setLoadingTrue}) => {

  const auth = useSelector(({authorization}: appGlobalStore) => authorization);

  React.useEffect(() => {
    // console.log(auth);
  }, [auth]);

  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem('user');
    loggedOut();
    setLoadingTrue();
    // history.push('/login')
  }

  return (
    <div className={styles.logout} onClick={handleLogout}>
      <img className={styles.iconLogout} src={logoutSvg} alt="logout"/>
      {
        stateElem && 
      <p className={styles.text}>Выход</p>}
    </div>
  )
}

export default connector(Logout) 