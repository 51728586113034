import {call, takeEvery, put} from "redux-saga/effects";
import {PATCH_NOTIFICATION_SAGA} from "../../actionTypes";
import {api} from "../../../../../api/restClient";


export type PatchNotificationAsyncType = {
  type: typeof PATCH_NOTIFICATION_SAGA,
  payload: {
    notification_id: number,
    variables: object,
  }
}

const patchApiNotification = (patchObj: { notification_id: number, variable: object }) => {
  return api.patch(`notification/${patchObj.notification_id}/`, patchObj.variable)
    .catch((err) => console.log(err))
}


function* patchNotificationAsync(action:PatchNotificationAsyncType) {
  try {
    const patchObj = yield {
      notification_id: action.payload.notification_id,
      variable: action.payload.variables,
    }
    const response = yield call(patchApiNotification, patchObj)
    //console.log(action)
  } catch (err) {
    console.log(err)
  }
}


export function* patchNotificationSagas() {
  yield takeEvery(PATCH_NOTIFICATION_SAGA, patchNotificationAsync)
}