import { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";

export function ProtectedRoute({ component: Component, ...props  }) {

  return (
    <Route>
      { props.loggedIn ? <Component /> : <Redirect to="/login" /> }
    </Route>
)}

