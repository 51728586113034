import React, { FC } from "react";
import styles from './addition.module.scss';
import { AdditionBlockType } from "../../../../../../types/cardTypes";
import NoVariable from "../no-variable/no-variable";
import OnlineStatus from "../online-status/online-status";

type AdditionType = {
  item: AdditionBlockType
}

const Addition: FC<AdditionType> = ({ item }) => {

  let demention = null

  switch (item?.value_type) {
		case 'celsius': {
			demention = '°C';
			break;
		}
		case 'percent': {
			demention = '%';
			break;
		}
		case 'days': {
			demention = 'days';
			break;
		}
		case 'hours': {
			demention = 'hours';
			break;
		}
		case 'minutes': {
			demention = 'min';
			break;
		}
		case 'seconds': {
			demention = 'sec';
			break;
		}
		default:
			demention = '';
	}


  return (
    item.variable.value ? (
      <div className={styles.errorWrapper}>
        <p className={styles.value}>{
          `${item.variable.value || "нет"} ${demention}`
        }</p>
        <div className={styles.description}>{item.description}<OnlineStatus isOnline={item.variable.is_online_device} /></div>
      </div>
    ) : (
      <NoVariable />
    )
  )
}

export default Addition;
