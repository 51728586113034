import React, { FC } from 'react';
import { ClimateItemType } from '../../../../../../../types/cardTypes';

type DehumidificationSvgType = {
	item: ClimateItemType;
};

const DehumidificationSvg: FC<DehumidificationSvgType> = ({ item }) => {
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0)">
				<path
					d="M15.4155 5.789C14.1885 4.223 12.9195 2.606 11.8965 0.869C11.7615 0.641 11.5155 0.5 11.25 0.5C10.9845 0.5 10.7385 0.641 10.605 0.869C9.582 2.6045 8.313 4.223 7.086 5.7875C4.599 8.9585 2.25 11.954 2.25 15.5C2.25 20.4635 6.2865 24.5 11.25 24.5C16.2135 24.5 20.25 20.4635 20.25 15.5C20.25 11.9555 17.9025 8.96 15.4155 5.789ZM11.25 23C7.1145 23 3.75 19.6355 3.75 15.5C3.75 12.4715 5.943 9.6755 8.2665 6.713C9.2805 5.42 10.3215 4.0925 11.25 2.6705C12.1785 4.0925 13.2195 5.4215 14.2335 6.7145C16.557 9.6755 18.75 12.473 18.75 15.5C18.75 19.6355 15.3855 23 11.25 23Z"
					fill={
						Number(item.value) > 0 || item.value === true
							? '#51B3D2'
							: '#ADADAD'
					}
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M5.09304 14.5C5.03248 14.8267 5 15.1595 5 15.5C5 18.809 7.691 21.5 11 21.5C14.309 21.5 17 18.809 17 15.5C17 15.1596 16.9675 14.8267 16.9069 14.5H5.09304Z"
					fill={
						Number(item.value) > 0 || item.value === true
							? '#51B3D2'
							: '#ADADAD'
					}
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(0 0.5)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
};
export default DehumidificationSvg;
