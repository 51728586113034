import React from 'react'
import styles from './status-icon.module.scss'
import { STATUS_ICONS } from './indicators'
import { StateColorTypes } from 'types/cardTypes'

interface IStatusIcon {
  colorOfStatus: StateColorTypes,
}

export const StatusIcon = ({ colorOfStatus }: IStatusIcon) => {

  let colorMod = ''
  switch (colorOfStatus) {
    case 'green': {
      colorMod = styles.green
      break
    }
    case 'red': {
      colorMod = styles.red
      break
    }
    case 'yellow': {
      colorMod = styles.yellow
      break
    }
    case 'blue': {
      colorMod = styles.blue
      break
    }
    default: colorMod = ''
  }

  return (
    <div className={`${styles.statusIcon} ${colorMod}`}>
      {STATUS_ICONS.STATUS_ICON_DEFAULT}
    </div>
  )
}
