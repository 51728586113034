import React, {FC} from "react";

type ArrowSvgType = {
  disclosure: boolean
}

const ArrowSvg: FC<ArrowSvgType> = ({disclosure}) => {

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{transform: disclosure ? "rotate(360deg)" : "rotate(180deg)",}}
    >
      <path d="M15 18L9 12L15 6" stroke="#ECECEC" strokeWidth="1.54" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}

export default ArrowSvg;