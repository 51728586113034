import { GET_ZONES_CARD_ACTION } from '../../actionTypes';
import { CardType, DndType } from '../../../../../types/cardTypes';

export type getZonesCardType = {
	type: typeof GET_ZONES_CARD_ACTION;
	payload: {
		cards: DndType | {};
	};
};

export const getZonesCard = (data) => ({
	type: data.type,
	payload: data.payload,
});
