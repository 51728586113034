import {initStateType} from "../../reducer";
import {getZonesCardType} from "../../actions/realty/getZonesCard";
import produce from "immer";
import {CardType, DndType} from "../../../../../types/cardTypes";
import {WritableDraft} from "immer/dist/types/types-external";

export const getZonesCardReducer = (state: initStateType, action: getZonesCardType) =>
  produce(state, ((draft: WritableDraft<initStateType>) => {

    const dndCards: DndType | {} = action.payload.cards

    let newCards:CardType[] = []
    for (let key in dndCards) {
      dndCards[key].items.map((card: CardType) => newCards = [...newCards, {
        column_id: card.column_id,
        content: card.content,
        icon: card.icon,
        id: card.id,
        id_str: card.id_str,
        order_id: card.order_id,
        title: card.title,
        zone_id: card.zone_id,
      }])
    }

    draft.cards = newCards
    draft.dndCards = dndCards

  }))