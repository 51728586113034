import { SET_CURRENT_ZONE } from '../../actionTypes';

export type setCurrentZoneType = {
	type: typeof SET_CURRENT_ZONE;
	payload: {
		zone_id: number;
	};
};

export const setCurrentZone = (data) => ({
	type: data.type,
	payload: data.payload,
});
