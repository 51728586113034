import React, { FC } from "react";
import styles from './online-status.module.scss';

type OnlineStatusType = {
  isOnline: boolean
}


const OnlineStatus: FC<OnlineStatusType> = ({ isOnline }) => {
  return (
    <>
      {!isOnline  && <div className={styles.container}>
      <div className={styles.dot} />
      <div className={`${styles.offline} ${styles.description}`}>
        Нет связи</div>
        </div>}
      {/*isOnline === null && <div className={styles.description}>Переменная не связана с устройством</div>*/}
    </> 
    )

}

export default OnlineStatus;