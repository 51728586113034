import {Switch, Route, Redirect} from "react-router-dom";

import { HomePage } from "../pages/home/home";
import { ProtectedRoute } from "./components";

import {
  Login,
  RestorePasswordInfo,
  RestorePasswordForm,
  GreetingPage,
} from "../pages/authorization";
import { connector } from "./container";

const Router = (props) => {
  const { authorization } = props;
  return (
          <Switch>
              <ProtectedRoute
                  exact
                  path="/"
                  loggedIn={authorization.loggedIn}
                  component={HomePage}
              />
              <Route exact path="/login" component={Login} />
              <Route exact path="/restore-password" component={RestorePasswordForm} />
              <Route
                  exact
                  path="/restore-password-info"
                  component={RestorePasswordInfo}
              />
              <Route exact path="/greeting" component={GreetingPage} />
              <Route path="*">
                  <Redirect to="/" />
              </Route>
          </Switch>
  );
}

export default connector(Router);
