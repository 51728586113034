import React, { memo } from 'react';
import { closedSvg, errorSvg, successSvg } from '../images';
import styles from './popup-item.module.scss';
import { popupStatusTypes } from 'pages/home/redux-sagas/sagas/poll/types';

interface IPopupItem {
	message: string;
	id: string;
	deletePopupHandler: (id: string) => void;
	type: popupStatusTypes;
}

const PopupItem: React.FC<IPopupItem> = memo(
	({ message, id, deletePopupHandler, type }) => {
		const [hide, setHide] = React.useState(false);

		React.useEffect(() => {
			message === '' ? setHide(false) : setHide(true);
		}, [message]);

		const handleRemovePopup = (popupID: string) => {
			setHide((prev) => !prev);
			deletePopupHandler(popupID);
		};

		let icon = null;

		switch (type) {
			case popupStatusTypes.ERROR: {
				icon = errorSvg;
				break;
			}
			case popupStatusTypes.SUCCESS: {
				icon = successSvg;
				break;
			}
			default: {
				icon = null;
			}
		}

		return (
			<li className={`${styles.popup} ${hide || styles.popup_hide}`}>
				<div className={styles.messageWrapper}>
					<img src={icon} alt="status icon" />
					<h2 className={styles.title}>{message}</h2>
				</div>
				<button className={styles.close} onClick={() => handleRemovePopup(id)}>
					<img className={styles.closeIcon} src={closedSvg} alt="popup" />
				</button>
			</li>
		);
	},
);

export default PopupItem;
