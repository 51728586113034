import { connector } from "../../container";
import logo from "../../images/climatech-logoblack.svg";
import smartHouse from "../../images/smartHouse.png";
import styles from "./startPage.module.scss";

export type Props = {
  children?: any;
  authorization?: any;
};

const StartPage = (props: Props) => {
  const { children, authorization } = props;
  const { phone, image } = authorization;

  return (
    <div className={styles.wrapper}>
      <div className={styles.leftSide}>
        <div className={styles.leftWrapper}>
          <img src={logo} className={styles.logo} alt="Логотип компании"/>

          {children}

          <p className={styles.bottomText}>
            Возникли вопросы?
            <span className={styles.phone}>
              {phone ? phone : "+7 (921) 333-33-33"}
            </span>
          </p>
        </div>
      </div>

      <div className={styles.rightSide}>
        <img
          src={image ? image : smartHouse}
          alt="Умный дом"
          className={styles.mainImage}
        />
      </div>
    </div>
  );
};

export default connector(StartPage);
