import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import * as actions from "../pages/authorization/redux-sagas/actions";
import * as selectors from "../pages/authorization/redux-sagas/selectors";

import * as homeActions from "../pages/home/redux-sagas/actions"
import * as homeSelectors from "../pages/home/redux-sagas/selectors"

const mapStateToProps = createStructuredSelector({
  authorization: selectors.authPageStateSelector,
  message: homeSelectors.messageSelector
});

const mapDispatchToProps = {
  loggedOut: actions.loggedOut,
  loggedIn: actions.loggedIn,
  setLoadingTrue: actions.setLoadingTrue,
  setLoadingFalse: actions.setLoadingFalse,
  setFullUser: actions.setUser,
  setMessage: homeActions.setMessage
};

export const connector = connect(mapStateToProps, mapDispatchToProps);
