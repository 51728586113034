import createReducer from 'store/createReducer'

import * as reducers from './reducers'
import * as t from './actionTypes'

const initState = {
    loggedIn: false,
    loading: false,
    errorText: null,
    image: null,
    phone: null,
    emailForRestorePassword: null,
    user: {
      email: null,
      fullName: null,
      phone: null,
      avatar: null
    }
}

const authorizationReducer = createReducer(initState, {
  [t.LOGGED_IN]: reducers.loggedInReducer,
  [t.LOGGED_OUT]: reducers.loggedOutReducer,
  [t.SET_EMAIL_FOR_RESTORE_PASSWORD]: reducers.setEmailForRestorePasswordReducer,
  [t.SET_LOADING_TRUE]: reducers.setLoadingTrueReducer,
  [t.SET_LOADING_FALSE]: reducers.setLoadingFalseReducer,
  [t.SHOW_ERROR]: reducers.showErrorReducer,
  [t.SET_IMAGE_AND_PHONE]: reducers.setImageAndPhoneReducer,
  [t.SET_USER]: reducers.setUserReducer,
})

export default authorizationReducer