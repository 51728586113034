import {all, put, call, select, takeEvery} from "redux-saga/effects";
import {api} from "../../../../../api/restClient";
import {GET_ZONES_ACTION, GET_ZONES_CARD_ACTION, GET_ZONES_CARDS_SAGA, GET_ZONES_SAGA} from "../../actionTypes";
import {GetZonesType} from "../../actions/realty/getZones";
import {GetZonesCardSagaType} from "./getZonesCardsAsync";
import {getZonesCard, getZonesCardType} from "../../actions/realty/getZonesCard";


const getApiZones = (id) => {
  return api.get(`realty/${id}/zone/`)
    .then((res) => res?.data)
    .catch((err) => console.log(err))
}

function* getZonesAsync(action) {
  try {
    const response = yield call(getApiZones, action.payload.realty_id)
    const zonesAction: GetZonesType = {
      type: GET_ZONES_ACTION,
      payload: {
        realty_id: action.payload.realty_id,
        zones: response,
      }
    }
    yield put(zonesAction)
    if (response.length) {
      const zonesCardSaga: GetZonesCardSagaType = {
        type: GET_ZONES_CARDS_SAGA,
        payload: {
          realty_id: response[0].realty_id,
          zones_id: response[0].id,
        }
      }
      yield put(zonesCardSaga)
    } else {
      const zonesCardAction: getZonesCardType = {
        type: GET_ZONES_CARD_ACTION,
        payload: {
          cards: {
            "id-1": [],
            "id-2": [],
            "id-3": [],
          }
        }


      }
      yield put(getZonesCard(zonesCardAction))
    }

  } catch (err) {
    console.log('zones response addition', err)
  }

}

export function* getZonesSagas() {
  yield takeEvery(GET_ZONES_SAGA, getZonesAsync)
}
