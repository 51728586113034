import { connector } from "app/container";
import React, { FC } from "react";
import styles from "./user-prompt.module.scss";

type UserPromptType = {
  stateElem: boolean,
  authorization: any
}


const UserPrompt: FC<UserPromptType> = (props) => {
  const { stateElem, authorization } = props;
  const phone = authorization.phone
  return (
    <React.Fragment>
      {
        stateElem && 
        <div>
          <p className={styles.description}>
            Возникли трудности?
          </p>
          <a className={styles.phone} href={`tel:${phone}`}>
            {phone? phone : '+7 (911) 723-54-28'}
          </a>
        </div>
      }
    </React.Fragment>
  );
};

export default connector(UserPrompt);