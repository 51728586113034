export function Eye() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.99984 9.33342C8.73622 9.33342 9.33317 8.73646 9.33317 8.00008C9.33317 7.2637 8.73622 6.66675 7.99984 6.66675C7.26346 6.66675 6.6665 7.2637 6.6665 8.00008C6.6665 8.73646 7.26346 9.33342 7.99984 9.33342Z"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6668 7.99992C12.8888 11.1113 10.6668 12.6666 8.00016 12.6666C5.3335 12.6666 3.1115 11.1113 1.3335 7.99992C3.1115 4.88859 5.3335 3.33325 8.00016 3.33325C10.6668 3.33325 12.8888 4.88859 14.6668 7.99992Z"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
