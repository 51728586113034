import React from 'react'
import styles from './styles.module.scss'

interface IButton {
  customClass?: string,
  clickHandler: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  text: string
}

const Button = ({clickHandler, customClass, text}: IButton) => {
  return (
    <button onClick={clickHandler} className={customClass?.length > 0 ? customClass : styles.commonButton}>{text}</button>
  )
}

export default Button