import { useState } from "react";
import { useHistory } from "react-router-dom";
import { FormPage, InputEmail } from "./parts";
import { useFormWithValidation } from "../../utils/validation";
import { sendEmail } from "../../api/authApi";
import { connector } from "./container";

const RestorePasswordForm = (props) => {
  const { setEmail, authorization } = props;
  const { phone } = authorization;

  const [isError, setIsError] = useState(false);

  const history = useHistory();

  const { values, handleChange, errors, isValid } = useFormWithValidation();

  const { email } = values;

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail(values).then((res) => {
      if (res === null) {
        setIsError(true);
      }
      setEmail(email);
      history.push("/restore-password-info");
    });
  };

  const tipText = isError
    ? `Учетная запись с указанным адресом электронной почты не найдена. Проверьте введенные данные и повторите попытку или свяжитесь с нами по телефону ${
        phone ? phone : "+7(921) 333-33-33"
      }`
    : "Введите e-mail, предоставленный администратору";

  return (
    <FormPage
      linkTo="/login"
      title="Восстановление пароля"
      tipText={tipText}
      linkText="Войти"
      text="Я вспомнил пароль?"
      buttonText="Восстановить пароль"
      isValid={isValid}
      onSubmit={handleSubmit}
      isError={isError}
    >
      <InputEmail onChange={handleChange} errorText={errors.email} />
    </FormPage>
  );
};

export default connector(RestorePasswordForm);
