import {call, put, takeEvery} from "redux-saga/effects";
import {GET_ZONES_CARD_ACTION, GET_ZONES_CARDS_SAGA} from "../../actionTypes";
import {api} from "../../../../../api/restClient";
import {getZonesCard, getZonesCardType} from "../../actions/realty/getZonesCard";

export type GetZonesCardSagaType = {
  type: typeof GET_ZONES_CARDS_SAGA,
  payload: {
    realty_id: number,
    zones_id: number,
  }
}

const getApiZonesCards = (obj: { realty_id: number, zones_id: number }) => {
  return api.get(`realty/${obj.realty_id}/zone/${obj.zones_id}/card/`)
    .then((res) => res.data)
    .catch((err) => console.log(err))
}


function* getZonesCardsAsync(action: GetZonesCardSagaType) {
  const response = yield call(getApiZonesCards, action.payload)
  const zonesCardAction: getZonesCardType = {
    type: GET_ZONES_CARD_ACTION,
    payload: {cards: response},
  }
  yield put(getZonesCard(zonesCardAction))
}

export function* getZonesCardsSagas() {
  yield takeEvery(GET_ZONES_CARDS_SAGA, getZonesCardsAsync)
}