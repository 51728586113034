import {all, put, call, select, takeEvery} from "redux-saga/effects";
import {GET_REALTY_ACTION, GET_REALTY_SAGA, GET_ZONES_SAGA} from "../../actionTypes";
import {api} from "../../../../../api/restClient";
import {getRealty} from "../../actions/realty";
import {RealtyType, RealtyTypeAPI} from "../../../../../types/types";
import {GetZonesType} from "../../actions/realty/getZones";
import {GetRealtyType} from "../../actions/realty/getRealty";

const getApiRealty = () => {
  return api.get('realty/')
    .then((res) => res.data)
    .catch((err) => console.log(err))
}

function* getRealtyAsync() {
  try {
    const response: RealtyTypeAPI[] = yield call(getApiRealty)

    const realtyAction: GetRealtyType = {
      type: GET_REALTY_ACTION,
      payload: {
        data: response
      }
    }
    const zonesAction: GetZonesType = {
      type: GET_ZONES_SAGA,
      payload: {
        realty_id: response[0].id,
        zones: []
      }
    }

    yield put(getRealty(realtyAction))
    yield put(zonesAction)
  } catch (err) {
    console.log(err)
  }


}

export function* getRealtySagas() {
  yield takeEvery(GET_REALTY_SAGA, getRealtyAsync)
}