import { connector } from "../../container";
import { StartPage } from "..";
import * as Images from "../../images";
import { Button, CustomLink } from "../../../../ui-kit";
import styles from "./form-page.module.scss";

export type Props = {
  onSubmit: (e: any) => void;
  isError?: boolean;
  isValid: boolean;
  title: string;
  tipText: string;
  buttonText: string;
  linkText: string;
  text: string;
  linkTo: string;
  handleAuthorization?: any;
  children?: any;
};

const FormPage = (props: Props) => {
  const {
    onSubmit,
    buttonText,
    linkText,
    text,
    title,
    linkTo,
    tipText,
    isValid,
    children,
    isError,
  } = props;

  return (
    <>
      <StartPage>
        <form
          onSubmit={onSubmit}
          className={styles.form}
          noValidate
          autoComplete="new-password"
        >
          <h1 className={styles.title}>{title}</h1>
          {isError && (
            <div className={styles.errorWrapper}>
              <Images.Error />
              <p className={styles.errorText}>Ошибка</p>
            </div>
          )}
          <p className={isError ? styles.tipTextError : styles.tipText}>
            {tipText}
          </p>

          {children}

          <CustomLink
            to={linkTo}
            text={text}
            linkText={linkText}
            className={styles.link}
            textClassName={styles.text}
          />
          <Button
            type="submit"
            text={buttonText}
            className={styles.button}
            disabled={!isValid}
          />
        </form>
      </StartPage>
    </>
  );
};

export default connector(FormPage);
