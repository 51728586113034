import React, {FC} from "react";
import {ZoneType} from "../../../types/types";
import {defaultSvg} from "../images/rooms";
import styles from './rooms.module.scss';

type RoomsType = {
  zones: ZoneType[],
  activeRoom: number,
  stateElem: boolean,
  handleZone: (realty_id: number, zones_id: number, index: number) => void,
  isLarge: boolean,
}

const Rooms: FC<RoomsType> = ({ 
  zones, activeRoom, stateElem, handleZone, isLarge,
}) => {

  const getRoomClass = (index, activeIndex) => {
    return `${styles.roomWrapper} ${activeIndex === index && styles.roomWrapper_active}`;
  };

  const getRoomTopClass = (index, activeIndex) => {
    return `${styles.roomTop} ${activeIndex === index && styles.roomTop_active}`;
  };

  return (
    <React.Fragment>
      {
        isLarge ?
          <div className={styles.roomsWrapper}>
            {zones?.map((item: ZoneType, index: number) => {
              return (
                <div
                  key={item.id}
                  onClick={_ => handleZone(item.realty_id, item.id, index)}
                  className={getRoomClass(index, activeRoom)}
                  style={{
                    width: stateElem ? 168 : "auto",
                    backgroundColor: activeRoom === index ? '#60BF69' : "transparent"
                  }}
                >
                  <img src={item.file || defaultSvg} alt="-" className={styles.img}/>
                  {
                    stateElem && <p className={styles.text}>{item.title}</p>
                  }
                </div>
              )
            })}
          </div> 
          :
          <div className={styles.roomsWrapperTop}>
            {zones?.map((item: ZoneType, index: number) => {
              return (
                <div
                  key={item.id}
                  onClick={_ => handleZone(item.realty_id, item.id, index)}
                  className={getRoomTopClass(index, activeRoom)}
                >
                  <img src={item.file || defaultSvg} alt="-" className={styles.roomTop__image}/>
                  <p className={styles.roomTop__text}>
                    {item.title}
                  </p>
                </div>
              )
            })}
          </div>
      }
    </React.Fragment>
  )
}

export default Rooms;