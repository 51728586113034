import React, { FC, useState } from "react";
import { connector } from "app/container";
import { uploadAvatar } from "../../../api/authApi";
import { avatarIcon } from "../images";
import { Spinner } from "../spinner/spinner";
import styles from "./userInfo.module.scss";

type UserInfoType = {
  onClick?: () => void;
  setFullUser: (any) => void;
  authorization: any;
  setMessage: any;
};

const UserInfo: FC<UserInfoType> = ({
  onClick,
  setFullUser,
  authorization,
  setMessage
}) => {
  const [loader, setLoader] = useState(false);

  const username = authorization.user.full_name;
  const avatar = authorization.user.avatar;

  const imageUpload = (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setMessage("File size exceeds the allowed limit (10 MB)");
        return;
      }
      const supportedMimetypes = ["image/jpeg", "image/png"];
      if (!supportedMimetypes.includes(file.type)) {
        setMessage("Unsupported file type. Only JPEG, PNG, and GIF images are allowed");
        return;
      }
      setLoader(true);
      formData.append("file", event.target.files[0]);
      uploadAvatar(formData)
        .then((res) => {
          if (res.status === 200) {
            setFullUser(res.data);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoader(false));
    }
  };

  return (
    <React.Fragment>
      <div className={styles.wrapperAvatar} onClick={onClick}>
        {loader ? <Spinner /> : null}
        {onClick || (
          <>
            <label htmlFor="pic-upload" className={styles.uploadLabel} />
            <input
              id="pic-upload"
              className={styles.upload}
              type="file"
              onChange={imageUpload}
              accept="image/png, image/jpeg"
            />
          </>
        )}
        <div className={styles.online} />
        <img
          className={styles.avatar}
          src={avatar || avatarIcon}
          alt="Аватар"
        />
      </div>
      <p className={styles.name}>{`${username || "Пользователь"}`}</p>
    </React.Fragment>
  );
};

export default connector(UserInfo);
