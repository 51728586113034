import styles from './inputs.module.scss';
import * as Image from "../../images";

export type Props = {
  errorText: string;
  onChange: any;
  login: string;
};

export const InputLogin = (props: Props) => {
  const { onChange, errorText,
     login
    } = props;
  return (
    <>
      <div className={styles.inputWrapper}>
        <div className={styles.icon}>
          <Image.User />
        </div>
        <input
          name="login"
          type="text"
          className={styles.input}
          placeholder="Логин"
          onChange={onChange}
          required
          minLength={5}
          value={login}
        />
      </div>
      <span className={styles.error}>{errorText}</span>
    </>
  );
};
