import React, { FC, useRef, useState } from 'react';
import styles from './card.module.scss';
import { Arrow,  dragEndDropSvg } from './images/index';
import { TitleCard } from './components/index';
import { CardType, ContentCardType } from '../../../types/cardTypes';
import RenderZeroContent from './components/render-zero-content/render-zero-content';
import RenderContent from './components/render-content/render-content';
import OnOff from './components/blocks/onOff/onOff';
import AllOff from './components/blocks/all-off/all-off';

type CardPropsType = {
	card: CardType;
	patchVariableBlock: (block, value, type) => void;
	provided: any;
};

const Card: FC<CardPropsType> = ({ card, patchVariableBlock, provided }) => {
	const [disclosure, setDisclosure] = useState(false);
	const wrapperEl = useRef();

	const handleArrow = () => {
		setDisclosure((disclosure) => !disclosure);
	};

	return (
		<div className={styles.card}>
			<img
				className={styles.dragImg}
				src={dragEndDropSvg}
				alt="drag"
				ref={provided.innerRef}
				{...provided.droppableProps}
				{...provided.dragHandleProps}
			/>
			<div className={styles.headerCard}>
				<div className={styles.headerCard__item}>
					<TitleCard title={card.title} icon={card.icon} />
					{card.content.length > 1 && (
						<Arrow disclosure={disclosure} handleArrow={handleArrow} />
					)}
				</div>
				<div className={styles.item}>
					<RenderZeroContent
						content={card?.content[0]}
						patchVariableBlock={patchVariableBlock}
					/>
					{/*<AllOff/>*/}
				</div>
			</div>
			<div
				className={styles.wrapperItems}
				style={{
					//@ts-ignore
					height: disclosure ? wrapperEl.current.scrollHeight : 0,
				}}
				ref={wrapperEl}
			>
				{card?.content?.slice(1).map((item: ContentCardType) => (
					<RenderContent
						content={item}
						disclosure={disclosure}
						patchVariableBlock={patchVariableBlock}
						key={item.id}
					/>
				))}
			</div>
		</div>
	);
};

export default Card;
