import React, { FC } from "react";
import styles from "./spinner.module.scss";

type Prop = {
  small?: boolean;
  middleSmall?: boolean;
  verySmall?: boolean;
};

export const Spinner: FC<Prop> = ({ small, middleSmall, verySmall }) => {
  return (
    <div className={styles.spinner}>
      <div className={styles.ring} />
    </div>
  );
};
