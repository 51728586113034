import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connector } from "app/container";
import { uploadAvatar } from "../../../api/authApi";
import { loggedOut } from "pages/authorization/redux-sagas/actions";
import { Spinner } from "../spinner/spinner";
import { avatarIcon } from "../images";
import styles from "./userForm.module.scss";

type UserFormType = {
  onCloseClick: () => void;
  setFullUser: (any) => void;
  authorization: any;
  setMessage: any;
};

const UserForm: FC<UserFormType> = ({
  onCloseClick,
  setFullUser,
  authorization,
  setMessage
}) => {
  const [loader, setLoader] = useState(false);

  const history = useHistory();

  const username = authorization.user.full_name;
  const avatar = authorization.user.avatar;

  const handleLogout = () => {
    localStorage.removeItem("user");
    loggedOut();
    history.push("/login");
  };

  const imageUpload = (event) => {
    const formData = new FormData();
    const file = event.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        setMessage("File size exceeds the allowed limit (10 MB)");
        return;
      }
      const supportedMimetypes = ["image/jpeg", "image/png"];
      if (!supportedMimetypes.includes(file.type)) {
        setMessage("Unsupported file type. Only JPEG, PNG, and GIF images are allowed");
        return;
      }
      setLoader(true);
      formData.append("file", event.target.files[0]);
      uploadAvatar(formData)
        .then((res) => {
          if (res.status === 200) {
            setFullUser(res.data);
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setLoader(false));
    }
  };

  return (
    <div className={styles.userForm}>
      <div className={styles.buttonGroup}>
        <button
          type="button"
          className={styles.buttonClose}
          onClick={onCloseClick}
        />
        <button
          type="button"
          className={styles.buttonLogout}
          onClick={handleLogout}
        />
      </div>

      <div className={styles.wrapperAvatar}>
        {loader ? <Spinner /> : null}
        <label htmlFor="pic-upload" className={styles.uploadLabel} />
        <input
          id="pic-upload"
          className={styles.upload}
          type="file"
          onChange={imageUpload}
          accept="image/png, image/jpeg"
        />
        <span className={styles.online} />
        <img
          className={styles.avatar}
          src={avatar || avatarIcon}
          alt="Аватар"
        />
      </div>

      <p className={styles.name}>{`${username || "Пользователь"}`}</p>
    </div>
  );
};

export default connector(UserForm);
