import {initStateType} from "../../reducer";
import {NotificationActionType} from "../../actions/realty/notificationAction";
import produce from "immer";
import {NotificationType} from "../../../../../types/types";

export const notificationReducer = (state: initStateType, action: NotificationActionType) =>
  produce(state, (draft => {
    const notification = action.payload.notifications
    const newNotification: NotificationType[] = notification.map((item: NotificationType) => {
      return {
        created_at: item.created_at,
        id: item.id,
        is_read: item.is_read,
        massage: item.massage,
      }
    })
    draft.notification = newNotification
  }))