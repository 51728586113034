import {call, takeEvery, put} from "redux-saga/effects";
import {api} from "../../../../../api/restClient";
import {GET_NOTIFICATION_SAGA, NOTIFICATION_ACTION} from "../../actionTypes";
import {NotificationActionType} from "../../actions/realty/notificationAction";

const getApiNotification = () => {
  return api.get('notification/')
    .then((res) => res.data)
    .catch((err) => console.log(err))
}


function* getNotificationAsync() {
  try {
    const response = yield call(getApiNotification)
    const notificationAction: NotificationActionType = {
      type: NOTIFICATION_ACTION,
      payload: {
        notifications: response
      }
    }
    yield put(notificationAction)
  } catch (err) {
    console.log(err)
  }
}

export function* getNotificationSagas() {
  yield takeEvery(GET_NOTIFICATION_SAGA, getNotificationAsync)
}