import { TOGGLE_NOTIFICATION_ACTION } from "../../actionTypes";

export type toggleNotificationType = {
  type: typeof TOGGLE_NOTIFICATION_ACTION,
  payload: {
    active: boolean,
  }
}

export const toggleNotification = (data: toggleNotificationType) => ({
  type: data.type,
  payload: data.payload,
})