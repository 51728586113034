import { StartPage } from "./parts";
import { connector } from "./container";
import styles from "./restorePass.module.scss";
import { Link } from "react-router-dom";

const RestorePasswordInfo = (props) => {
  const { authorization } = props;

  return (
    <StartPage>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>Восстановление пароля</h1>
        <p className={styles.sendMailTip}>
          Ссылка на восстановление пароля отправлена на адрес{" "}
          {authorization.email || 'example@.mail.com'}
        </p>
        <Link to='/login' className={styles.link}>&larr; Вернуться</Link>
      </div>
    </StartPage>
  );
};

export default connector(RestorePasswordInfo);
