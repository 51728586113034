import { api } from "./restClient";

export const handleSetUser = (tokenData) => {
  // console.log(`setUser ${tokenData}`);
  localStorage.setItem("user", JSON.stringify(tokenData.token));
  api.setUserToken(tokenData.token);
};

export const handleLogin = async (dataToSend) => {
  try {
    await api
      .post("authentication/token/obtaining/", dataToSend)
      .then((res) => {
        handleSetUser(res?.data);
      });
  } catch (error) {
    return "Неверно указан логин или пароль";
  }
};

export const verifyToken = async (data: any) => {
  const token = {
    token: data,
  };
  try {
    return await api
      .post("authentication/token/verification/", { token: data })
      .then((res) => {
        api.setUserToken(data);
        return res;
      });
  } catch (error) {
    console.log(error);
  }
};

export const sendEmail = async (data) => {
  try {
    return await api.post("accounts/sendemail/", data);
  } catch (error) {
    return null;
  }
};

export const uploadAvatar = async (data) => {
  try {
    return await api.post("accounts/uploadphoto/", data);
  } catch (error) {
    console.log(error);
    return null;
  }
};

export const getImageAndPhone = async () => {
  try {
    const response = await api.get("site-configuration/");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserByToken = async () => {
  try {
    const response = await api.get("accounts/get_by_token");
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// login: admin@gmail.com password: idontcare

// login: admin@admin.com
// password: a1841969

// escar1985@yandex.ru
// admin
