import React, { FC, MutableRefObject } from 'react';
import { closedSvg, frameGreenSvg } from './images';
import { NotificationType } from '../../types/types';
import { dateFormatDDMMYYY } from '../../utils/convertDateDD-MM-YYYY-HH-MM';
import styles from './modal-notice.module.scss';

type ModalNoticeType = {
	notification: NotificationType[];
	RefModalNotice: MutableRefObject<any>;
	handleNotification: (boolean) => void;
	mouseClickNotification: (e, card: NotificationType, isRead: boolean) => void;
};

const ModalNotice: FC<ModalNoticeType> = ({
	notification,
	handleNotification,
	mouseClickNotification,
	RefModalNotice,
}) => {
	const setModalHeight = () => {
		const height = document.querySelector('#root').clientHeight;
		return { height };
	};

	return (
		<div className={styles.modal} ref={RefModalNotice} style={setModalHeight()}>
			<img
				className={styles.closeButton}
				onClick={() => handleNotification(false)}
				src={closedSvg}
				alt="close"
			/>
			<div className={styles.modalDataContainer}>
				<h2 className={styles.title}>Уведомления</h2>
				{notification.length === 0 ? (
					<p>Нет новых уведомлений</p>
				) : (
					notification.map((item: NotificationType) => {
						return (
							<div className={styles.notificationBlock} key={item.id}>
								{!item.is_read ? (
									<img
										className={styles.notificationBlock__frame}
										onClick={(e) => {
											mouseClickNotification(e, item, true);
										}}
										src={frameGreenSvg}
										alt="frame"
									/>
								) : (
									<div style={{ width: '29px' }} />
								)}
								<div className={styles.notificationBlock__item}>
									<p className={styles.notificationBlock__date}>
										{dateFormatDDMMYYY(item.created_at)}
									</p>
									<p className={styles.notificationBlock__text}>
										{item.massage}
									</p>
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
};

export default ModalNotice;
