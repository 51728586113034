export function Lock() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3335 7.33325H4.66683C3.93045 7.33325 3.3335 7.93021 3.3335 8.66659V12.6666C3.3335 13.403 3.93045 13.9999 4.66683 13.9999H11.3335C12.0699 13.9999 12.6668 13.403 12.6668 12.6666V8.66659C12.6668 7.93021 12.0699 7.33325 11.3335 7.33325Z"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00016 11.3333C8.36835 11.3333 8.66683 11.0349 8.66683 10.6667C8.66683 10.2985 8.36835 10 8.00016 10C7.63197 10 7.3335 10.2985 7.3335 10.6667C7.3335 11.0349 7.63197 11.3333 8.00016 11.3333Z"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.3335 7.33333V4.66667C5.3335 3.95942 5.61445 3.28115 6.11454 2.78105C6.61464 2.28095 7.29292 2 8.00016 2C8.70741 2 9.38568 2.28095 9.88578 2.78105C10.3859 3.28115 10.6668 3.95942 10.6668 4.66667V7.33333"
        stroke="#8B8B8B"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
