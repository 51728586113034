import React, {FC} from "react";
import styles from './title-card.module.scss';
import {conditionerSvg, noIconSvg} from "../../images/icon-card/index";

type TitleCardType = {
  title: string,
  icon: string,
}

const TitleCard: FC<TitleCardType> = ({
                                        title,
                                        icon,
                                      }) => {

  return (
    <div className={styles.titleCardWrapper}>
      <img src={ icon } alt="Иконка" onError={(e) => e.currentTarget.src = noIconSvg} />
      <p className={styles.text}>{title}</p>
    </div>
  )
}

export default TitleCard;