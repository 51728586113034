import { initStateType } from '../../reducer';
import produce from 'immer';
import { setCurrentRealtyType } from '../../actions/realty/setCurrentRealty';

export const setCurrentRealtyReducer = (
	state: initStateType,
	action: setCurrentRealtyType,
) =>
	produce(state, (draft) => {
		draft.currentRealty = action.payload.realty_id;
	});
