import { Link } from "react-router-dom";

export const CustomLink = (props) => {
  const { text, to, className, textClassName, linkText } = props;
  return (
    <p className={textClassName}>
      {text}
      <Link to={to} className={className}>{linkText}</Link>
    </p>
  );
};
