import { FC, MutableRefObject, useState } from "react";
import { NotificationType } from "../../types/types";
import { dateFormatDDMMYYY } from "../../utils/convertDateDD-MM-YYYY-HH-MM";

import ModalMessage from "./modal-message";
import styles from './modal-mobile.module.scss';
import { buttonBackSvg, circleGreen } from "./images";

type ModalMobileType = {
  notification: NotificationType[]
  RefModalNotice: MutableRefObject<any>
  mouseClickNotification: (message: NotificationType, isSeen: boolean) => void,
  handleNotification: (boolean) => void
}

const ModalMobile: FC<ModalMobileType> = ({
  notification,
  handleNotification,
  RefModalNotice,
  mouseClickNotification,
}) => {

  const NullMessage = {created_at: null, id: null, is_read: null, massage: null};
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState(NullMessage);

  const setModalHeight = () => {
    const height = document.querySelector("#root").clientHeight;
    return { height };
  };

  const handleClick = (item) => {
    mouseClickNotification(item, true);
    setMessage(item);
    setIsMessageOpen(true);
  };

  const handleClose = () => {
    setIsMessageOpen(false);
    setMessage(NullMessage);
  };

  return (
    <div className={styles.modal} ref={RefModalNotice} style={setModalHeight()}>
      <div className={styles.modalHeader}>
        <h2 className={styles.title}>Уведомления</h2>
        <img 
          className={styles.closeButton}
          onClick={() => handleNotification(false)}
          src={buttonBackSvg}
          alt="Назад"
        />
      </div>

      <div className={styles.modalDataContainer}>
        {
          notification.length === 0 ? (
            <p>Нет новых уведомлений</p>
          ) : (
            notification.map((item: NotificationType, index: number) => {
              return (
                <div 
                  key={index}
                  className={styles.notificationBlock} 
                  style={{ cursor: "pointer"}}
                  onClick={() => handleClick(item)}
                >
                  {
                    !item.is_read ? 
                    <img 
                      className={styles.notificationBlock__frame}
                      src={circleGreen}
                      alt="Новое"
                    /> :
                    <div style={{width: "29px"}}/>
                  }
                  <div className={styles.notificationBlock__item}>
                    <p className={styles.notificationBlock__date}>
                      {dateFormatDDMMYYY(item.created_at)}
                    </p>
                    <p className={styles.notificationBlock__text}>{item.massage}</p>
                  </div>
                </div>
              )
            })
          )
        }
      </div>
      { isMessageOpen && <ModalMessage notification={message} onClose={handleClose}/> }
    </div>
  )
}

export default ModalMobile;
