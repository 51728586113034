import React, { FC, memo } from 'react';
import styles from './popup.module.scss';
import PopupItem from './popup-item/popup-item';
import { IPopupNotificationItem } from 'pages/home/redux-sagas/reducer';

type PopupType = {
	notificationsArray: Array<IPopupNotificationItem>;
	deletePopupHandler: (id: string) => void;
};

const Popup: FC<PopupType> = memo(
	({ notificationsArray, deletePopupHandler }) => {
		return (
			<ul className={styles.wrapper}>
				{notificationsArray.map((item, i) => (
					<PopupItem
						type={item.type}
						deletePopupHandler={deletePopupHandler}
						key={`${item.id}${i}`}
						message={item.message}
						id={item.id}
					/>
				))}
			</ul>
		);
	},
);

export default Popup;
