export const GET_REALTY_ACTION = 'home/GET_REALTY_ACTION';
export const GET_ZONES_ACTION = 'home/GET_ZONES_ACTION';
export const GET_ZONES_CARD_ACTION = 'home/GET_ZONES_CARD_ACTION';
export const TOGGLE_NOTIFICATION_ACTION = 'home/TOGGLE_NOTIFICATION_ACTION';
export const NOTIFICATION_ACTION = 'home/NOTIFICATION_ACTION';
export const MESSAGE_ACTION = 'home/MESSAGE_ACTION';
export const SET_POPUP_NOTIFICATION_ACTION =
	'home/SET_POPUP_NOTIFICATION_ACTION';
export const CLEAR_POPUP_NOTIFICATION_ACTION =
	'home/CLEAR_POPUP_NOTIFICATION_ACTION';
export const SET_CURRENT_REALTY = 'home/SET_CURRENT_REALTY';
export const SET_CURRENT_ZONE = 'home/SET_CURRENT_ZONE';

// sagas type
export const GET_REALTY_SAGA = 'home/GET_REALTY_SAGA';
export const GET_ZONES_SAGA = 'home/GET_ZONES_SAGA';
export const GET_ZONES_CARDS_SAGA = 'home/GET_ZONES_CARDS_SAGA';
export const GET_NOTIFICATION_SAGA = 'home/GET_NOTIFICATION_SAGA';
export const PATCH_VARIABLE_CARDS_SAGA = 'home/PATCH_ZONES_CARDS_SAGA';
export const PATCH_CLIMATE_VARIABLE_CARD_SAGA =
	'home/PATCH_CLIMATE_VARIABLE_CARD_SAGA';
export const PATCH_CLIMATE_VARIABLE_CARD_SAGA_SUCCEEDED =
	'home/PATCH_CLIMATE_VARIABLE_CARD_SAGA_SUCCEDED';
export const PATCH_CLIMATE_VARIABLE_CARD_SAGA_FAILED =
	'home/PATCH_CLIMATE_VARIABLE_CARD_SAGA_FAILED';
export const PATCH_CARDS_SAGA = 'home/PATCH_CARDS_SAGA';
export const PATCH_NOTIFICATION_SAGA = 'home/PATCH_NOTIFICATION_SAGA';
export const PUT_SCRIPT_REQUEST_FAILED = 'home/PUT_SCRIPT_REQUEST_FAILED';
export const PUT_SCRIPT_REQUEST_SUCCEEDED = 'home/PUT_SCRIPT_REQUEST_SUCCEEDED';
