import React, {FC} from "react";
import styles from './render-zero-content.module.scss';
import {
  BlockType,
  ContentCardType,
} from "../../../../../types/cardTypes";
import RenderBlock from "../render-block";

type RenderZeroBlockType = {
  content: ContentCardType
  patchVariableBlock: (block, value, type) => void
}

const RenderZeroContent: FC<RenderZeroBlockType> = ({content, patchVariableBlock}) => {
  return (
    <>
      {content?.blocks
        .map((item: BlockType) => <RenderBlock block={item} patchVariableBlock={patchVariableBlock} key={item.id}/>)}
    </>
  )
}

export default RenderZeroContent;