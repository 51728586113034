import React, { FC, useEffect, useState } from 'react';
import styles from './switch-toggle.module.scss';
import { SwitchToggleBlockType } from '../../../../../../types/cardTypes';
import NoVariable from '../no-variable/no-variable';
import OnlineStatus from '../online-status/online-status';

type SwitchToggleType = {
	item?: SwitchToggleBlockType;
	patchVariableBlock: (block: SwitchToggleBlockType, value, type) => void;
};

const SwitchToggle: FC<SwitchToggleType> = ({ item, patchVariableBlock }) => {
	const [checked, setChecked] = useState(
		Number(item.variable.value) > 0 || item.variable.value === true
			? true
			: false,
	);

	useEffect(() => {
		setChecked(
			Number(item.variable.value) > 0 || item.variable.value === true
				? true
				: false,
		);
	}, [item]);

	const handleChecked = () => {
		setChecked((checked) => !checked);
		patchVariableBlock(item, !checked, item.type);
	};

	return item.variable ? (
		<div className={styles.switchWrapper}>
			<input
				className={styles.switchCheckbox}
				id={`react-switch-new`}
				type="checkbox"
				checked={checked}
				readOnly
			/>
			<label
				onClick={handleChecked}
				className={styles.switchLabel}
				htmlFor={`react-switch-new`}
				style={{ background: checked ? '#60BF69' : '#8D998D' }}
			>
				<span className={styles.switchButton} />
			</label>
			<p className={styles.text}>
				{item?.description}
				<OnlineStatus isOnline={item.variable.is_online_device} />
			</p>
		</div>
	) : (
		<NoVariable />
	);
};

export default SwitchToggle;
