import produce from "immer";
import {initStateType} from "../../reducer";
import {GetRealtyType} from "../../actions/realty/getRealty";
import {WritableDraft} from "immer/dist/types/types-external";
import {RealtyType, RealtyTypeAPI} from "../../../../../types/types";

export const getRealtyReducer = (state: initStateType, action: GetRealtyType) =>
  produce(state, (draft: WritableDraft<initStateType>) => {
    const realty: RealtyType[] = action.payload.data.map((item: RealtyTypeAPI): RealtyType => {
      return {
        id: item.id,
        order_id: item.order_id,
        addres: item.address_for_client,
        name: item.name_for_client,
        user: item.user,
      };
    });
    draft.realty = realty;
  });