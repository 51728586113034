import { useEffect, useState, FormEvent } from "react";
import { useHistory } from "react-router-dom";
import { connector } from "./container";
import { FormPage } from "./parts";
import { handleLogin, verifyToken } from "../../api/authApi";
import { InputLogin } from "./parts/inputs/inputLogin";
import { InputPassword } from "./parts";
import { useFormWithValidation } from "../../utils/validation";
import { getImageAndPhone, getUserByToken } from "../../api/authApi";

export type Props = {
  loggedIn: () => void;
  setLoadingTrue: () => void;
  setLoadingFalse: () => void;
  setImageAndPhone: (res: any) => void;
  showError: (text: string) => void;
  hideError: () => void;
  onSubmit: (e: any) => void;
  isValid: boolean;
  authorization: any;
  setFullUser: any;
  loading: boolean;
};

const Login = (props: Props) => {
  const {
    setImageAndPhone,
    loggedIn,
    setLoadingTrue,
    loading,
    setLoadingFalse,
    showError,
    setFullUser,
    authorization,
  } = props;

  const history = useHistory();

  const storage = localStorage.getItem("user");

  const initialState = {
    user: (storage && JSON.parse(storage)) || {},
  };

  const [token, setToken] = useState(initialState.user);

  const { values, handleChange, errors, isValid, resetForm, setValues } =
    useFormWithValidation();
  const { errorText } = authorization;

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoadingTrue();
    const { login, password } = values;

    handleLogin({ login, password }).then((res) => {
      // console.log("handleLogin");
      if (res === "Неверно указан логин или пароль") {
        setLoadingFalse();
        showError(res);
        setValues({
          login: "",
          email: "",
          password: "",
        });
      } else {
        loggedIn();
        history.push("/greeting");
        getUserByToken().then((res) => {
          setFullUser(res);
        });
      }
    });
    resetForm();
  };

  const tokenCheck = async () => {
    // console.log("tokenCheck");
    setLoadingTrue();
    try {
      await verifyToken(token)
        .then((res) => {
          // console.log(`tokenCheck ${JSON.stringify(res?.data['token'])}`);
          if (res === undefined) {
            setLoadingFalse();
            throw new Error("error in verification");
          } else {
            loggedIn();
            setToken(res?.data['token']);
            history.push("/");
            getUserByToken()
              .then((res) => {
                setFullUser(res);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log(JSON.stringify(user));
    if (JSON.stringify(token) === "{}") {
      return;
    } else {
      tokenCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    getImageAndPhone()
      .then((res) => setImageAndPhone(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(()=>console.log(JSON.stringify(token)), [token])

  return (
    <FormPage
      linkTo="/restore-password"
      title="Войти в систему"
      tipText="Используйте логин и пароль, предоставленный администратором"
      linkText="Восстановить"
      text="Забыли пароль?"
      buttonText="Войти"
      onSubmit={handleSubmit}
      isValid={isValid}
    >
      <InputLogin onChange={handleChange} errorText="" login={values.login} />
      <InputPassword
        onChange={handleChange}
        errorText={errorText || errors.password}
        password={values.password}
      />
    </FormPage>
  );
};

export default connector(Login);
