import React, { FC, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connector } from "../../pages/authorization/container";
import { useWindowDimensions } from "utils/useDimensions";
import { NotificationType, RealtyType } from "types/types";

import Notification from "./notification/notification";
import UserInfo from "./userInfo/userInfo";
import Property from "./property/property";
import UserForm from "./userForm/userForm";
import Popup from "../popup/popup";
import styles from "./header.module.scss";

type HeaderType = {
  activeRealty: number;
  realty: RealtyType[];
  notification: NotificationType[];
  handleRealty: (realty_id: number, realtyType, index: number) => void;
  handleNotification: (boolean) => void;
  message: any;
  setMessage: any;
};

const Header: FC<HeaderType> = ({
  activeRealty,
  realty,
  notification,
  handleRealty,
  handleNotification,
  message,
}) => {
  const [isWideScreen, setIsWideScreen] = useState(true);
  const [isInfoVisible, setIsInfoVisible] = useState(false);
  const { width } = useWindowDimensions();

  const store = useSelector((state: any) => state);

  useEffect(() => {
    setIsWideScreen(width > parseInt(styles.changeHeaderWidth));
  }, [width]);

  const handleInfoClick = () => {
    setIsInfoVisible(true);
  };

  const handleClose = () => {
    setIsInfoVisible(false);
  };

  return (
    <>
      {isWideScreen ? (
        <header className={styles.header}>
          <Property
            realty={realty}
            activeRealty={activeRealty}
            handleRealty={handleRealty}
          />
          <Notification
            notification={notification}
            handleNotification={handleNotification}
          />
          <UserInfo />
        </header>
      ) : (
        <header className={styles.header}>
          {isInfoVisible && <UserForm onCloseClick={handleClose} />}
          <UserInfo onClick={handleInfoClick} />
          <Property
            realty={realty}
            activeRealty={activeRealty}
            handleRealty={handleRealty}
          />
          <Notification
            notification={notification}
            handleNotification={handleNotification}
          />
        </header>
      )}
    </>
  );
};

export default connector(Header);
