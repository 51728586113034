import React, { FC } from "react";
import { BlockType } from "../../../../types/cardTypes";
import { SwitchToggle, Range, Addition, Color } from "./index";
import OnOff from "./blocks/onOff/onOff";
import AllOff from "./blocks/all-off/all-off";
import Climate from "./blocks/climate/climate";
import SystemStateIndicator from "./blocks/system-state/system-state";
import ScriptBlock from "./blocks/script-block/script-block";
import ErrorStateIndicator from "./blocks/error-state/error-state";

type RenderBlockType = {
  block: BlockType;
  patchVariableBlock: (block, value, type) => void;
};

const RenderBlock: FC<RenderBlockType> = ({ block, patchVariableBlock }) => {
  switch (block.type) {
    case "scale":
      return <Range item={block} patchVariableBlock={patchVariableBlock} />;
    case "switch-toggle":
      return (
        <SwitchToggle item={block} patchVariableBlock={patchVariableBlock} />
      );
    case "addition":
      return <Addition item={block} />;
    case "on-off":
      return <OnOff item={block} patchVariableBlock={patchVariableBlock} />;
    case "color":
      return <Color item={block} patchVariableBlock={patchVariableBlock} />;
    case "all-off":
      return <AllOff item={block} patchVariableBlock={patchVariableBlock} />;
    case "climate":
      return <Climate item={block} patchVariableBlock={patchVariableBlock} />;
    case "boolean_indicator":
      return <SystemStateIndicator item={block} />;
    case "error_indicator":
      return <ErrorStateIndicator item={block} />;
    case "script":
      return (
        <ScriptBlock patchVariableBlock={patchVariableBlock} item={block} />
      );
    default:
      return (
        <p
          style={{
            color: "#9d2c2c",
            fontSize: 12,
            border: "1px solid #353333",
            width: "100%",
            textAlign: "center",
            margin: "0px 20px 8px 0",
            borderRadius: 5,
            padding: 5,
          }}
        >
          Заданный тип блока не поддерживается в личном кабинете, обратитесь к
          администратору
        </p>
      );
  }
};

export default RenderBlock;
