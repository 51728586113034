import React, { FC, useEffect, useState } from 'react';
import styles from './notification.module.scss';
import { bellSvg } from '../images';
import { NotificationType } from '../../../types/types';

type notificationType = {
	notification: NotificationType[];
	handleNotification: (boolean) => void;
};

const Notification: FC<notificationType> = ({
	notification,
	handleNotification,
}) => {
	const [countNotification, setCountNotification] = useState<number>(null);

	useEffect(() => {
		let count = null;
		notification.map((item: NotificationType) => {
			if (item.is_read === false) {
				return setCountNotification((count += 1));
			}
		});
	}, [notification]);

	return (
		<div
			className={styles.wrapperBell}
			onClick={() => handleNotification(true)}
		>
			{countNotification !== null && (
				<p className={styles.countBell}>{countNotification}</p>
			)}
			<img src={bellSvg} alt="bell" />
		</div>
	);
};

export default Notification;
