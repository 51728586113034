import { useState } from "react";
import { Checkbox } from "../../../../ui-kit";
import * as Image from "../../images";
import styles from './inputs.module.scss'

export type Props = {
  onChange: any;
  errorText: string;
  password: string;
};

export const InputPassword = (props: Props) => {
  const { onChange, errorText,
     password
    } = props;

  const [checked, setChecked] = useState(false);

  const togglePasswordVisibility = (value: boolean) => {
    setChecked(value);
  };

  const inputType = `${checked ? "text" : "password"}`;
  return (
    <>
      <div className={styles.inputWrapper}>
        <div className={styles.icon}>
          <Image.Lock />
        </div>
        <input
          name="password"
          type={inputType}
          className={styles.input}
          placeholder="Пароль"
          onChange={onChange}
          required
          minLength={5}
          autoComplete="off"
          value={password}
        />
        <Checkbox
          ImageOn={Image.Eye}
          ImageOff={Image.EyeOff}
          onChange={togglePasswordVisibility}
        />
      </div>
      <span className={styles.error}>{errorText && errorText}</span>
    </>
  );
};
