import React, { FC } from "react";
import { ClimateItemType } from "../../../../../../../types/cardTypes";

type AirBlowOffType = {
  item: ClimateItemType
}

const AirBlowOff: FC<AirBlowOffType> = ({
  item,
}) => {

  return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M3 8.5H10C10.5933 8.5 11.1734 8.32405 11.6667 7.99441C12.1601 7.66477 12.5446 7.19623 12.7716 6.64805C12.9987 6.09987 13.0581 5.49667 12.9424 4.91473C12.8266 4.33279 12.5409 3.79824 12.1213 3.37868C11.7018 2.95912 11.1672 2.6734 10.5853 2.55765C10.0033 2.44189 9.40013 2.5013 8.85195 2.72836C8.30377 2.95543 7.83524 3.33994 7.50559 3.83329C7.17595 4.32664 7 4.90666 7 5.5"
				stroke={
					Number(item.value) > 0 || item.value === true ? '#515ED2' : '#ADADAD'
				}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 16.5H15C15.5933 16.5 16.1734 16.6759 16.6667 17.0056C17.1601 17.3352 17.5446 17.8038 17.7716 18.3519C17.9987 18.9001 18.0581 19.5033 17.9424 20.0853C17.8266 20.6672 17.5409 21.2018 17.1213 21.6213C16.7018 22.0409 16.1672 22.3266 15.5853 22.4424C15.0033 22.5581 14.4001 22.4987 13.8519 22.2716C13.3038 22.0446 12.8352 21.6601 12.5056 21.1667C12.1759 20.6734 12 20.0933 12 19.5"
				stroke={
					Number(item.value) > 0 || item.value === true ? '#515ED2' : '#ADADAD'
				}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2 12.5H19C19.5933 12.5 20.1734 12.3241 20.6667 11.9944C21.1601 11.6648 21.5446 11.1962 21.7716 10.6481C21.9987 10.0999 22.0581 9.49667 21.9424 8.91473C21.8266 8.33279 21.5409 7.79824 21.1213 7.37868C20.7018 6.95912 20.1672 6.6734 19.5853 6.55765C19.0033 6.44189 18.4001 6.5013 17.8519 6.72836C17.3038 6.95543 16.8352 7.33994 16.5056 7.83329C16.1759 8.32664 16 8.90666 16 9.5"
				stroke={
					Number(item.value) > 0 || item.value === true ? '#515ED2' : '#ADADAD'
				}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default AirBlowOff;
