import React, {FC, MutableRefObject, useEffect} from "react";
import ReactDOM from "react-dom";
import {toggleNotification, toggleNotificationType} from "../pages/home/redux-sagas/actions/realty/toggleNotification";
import {TOGGLE_NOTIFICATION_ACTION} from "../pages/home/redux-sagas/actionTypes";
import {useDispatch} from "react-redux";
import styles from './modal-overlay.module.scss';

type ModalOverlayType = {
  RefModalNotice: MutableRefObject<any>
  handleNotification: (boolean) => void
}

const ModalOverlay: FC<ModalOverlayType> = ({
  children,handleNotification, RefModalNotice
}) => {

  const modalRoot = document.querySelector('#modal-root');
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.addEventListener('click', handleOutsideClick)
    return () => document.body.removeEventListener('click', handleOutsideClick)
  }, []);

  const handleOutsideClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath());
    const findModalNotice = path.some((item) => item === RefModalNotice.current)
    const toggleNotificationAction: toggleNotificationType = {
      type: TOGGLE_NOTIFICATION_ACTION,
      payload: {
        active: false
      }
    }
    !findModalNotice && dispatch(toggleNotification(toggleNotificationAction))
  }

  const setOverlayHeight = () => {
    const height = document.querySelector("#root").clientHeight;
    return { height };
  };


  return ReactDOM.createPortal((
      <div className={styles.modalOverlay} style={setOverlayHeight()}>
        <div className={styles.modalContainer}>
          {children}
        </div>
      </div>
    ), modalRoot
  );
}

export default ModalOverlay;
