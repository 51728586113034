import React, { FC } from "react";
import styles from './no-variable.module.scss';


const NoVariable: FC = () => {
  return (
      <div style={{minWidth: '95px'}}>
        <p className={styles.description}>Необходимо задать переменную</p>
      </div>
    )

}

export default NoVariable;