import { FC, useEffect } from 'react';
import { NotificationType } from '../../types/types';
import { dateFormatDDMMYYY } from '../../utils/convertDateDD-MM-YYYY-HH-MM';

import { buttonBackSvg } from './images';
import styles from './modal-mobile.module.scss';

type ModalMessageType = {
	notification: NotificationType; // уведомление
	onClose: () => void; // что делать при нажатии на кнопку "Выход"
};

// --------------------------------------------------------------------------
// Просмотр выбранного уведомления на мобильном устройстве

const ModalMessage: FC<ModalMessageType> = ({ notification, onClose }) => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const setModalHeight = () => {
		const height = document.querySelector('#root').clientHeight + 44;
		return { height, overflow: 'hidden', zIndex: 201 };
	};

	return (
		<div className={styles.modal} style={setModalHeight()}>
			<div className={styles.modalHeader}>
				<h2 className={styles.title}>Просмотр уведомления</h2>
				<img
					className={styles.closeButton}
					onClick={onClose}
					src={buttonBackSvg}
					alt="Назад"
				/>
			</div>

			<div className={styles.modalDataContainer}>
				<div className={styles.notificationBlock}>
					<div className={styles.notificationBlock__item}>
						<p className={styles.notificationBlock__date}>
							{dateFormatDDMMYYY(notification.created_at)}
						</p>
						<p className={styles.notificationBlock__text}>
							{notification.massage}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ModalMessage;
