import React, { memo, useEffect } from 'react';
import {
	SystemStateBlockType,
	SystemStateBlockTypeVariable,
} from 'types/cardTypes';
import styles from './system-state.module.scss';
import NoVariable from '../no-variable/no-variable';
import { StatusIcon } from '../status-icon/status-icon';
import OnlineStatus from '../online-status/online-status';
import { useDispatch } from 'react-redux';
import { PatchZonesCardSagaType } from 'pages/home/redux-sagas/sagas/poll/patchZonesCardAsync';
import { PATCH_CLIMATE_VARIABLE_CARD_SAGA } from 'pages/home/redux-sagas/actionTypes';

interface ISystemStateIndicator {
	item: SystemStateBlockType;
}

const SystemStateIndicator: React.FC<ISystemStateIndicator> = memo(
	({ item }) => {
		const dispatch = useDispatch();
		const val: SystemStateBlockTypeVariable = item.variable;
		const type = item.type;

		useEffect(() => {
			dispatch<PatchZonesCardSagaType>({
				type: PATCH_CLIMATE_VARIABLE_CARD_SAGA,
				payload: {
					value: val.value,
					type,
					id: val.id,
				},
			});
		}, [item.variable.value]);

		if (item.variable) {
			const {
				color_of_one,
				text_of_one,
				text_of_zero,
				is_online_device,
				color_of_zero,
				value,
			} = item.variable;

			const color =
				Number(value) > 0 || value === true ? color_of_one : color_of_zero;
			const text =
				Number(value) > 0 || value === true ? text_of_one : text_of_zero;

			return (
				<div className={styles.errorWrapper}>
					<div className={styles.value}>
						<StatusIcon colorOfStatus={color} />
						{text}
					</div>
					<div className={styles.description}>
						{item.description}
						<OnlineStatus isOnline={is_online_device} />
					</div>
				</div>
			);
		} else {
			return <NoVariable />;
		}
	},
);

export default SystemStateIndicator;
