import { produce } from "immer";

export const loggedInReducer = (state) =>
  produce(state, (draft) => {
    draft.loggedIn = true;
    draft.loading = false;
  });

export const loggedOutReducer = (state) =>
  produce(state, (draft) => {
    draft.loggedIn = false;
  });