export const LOGGED_IN = 'authPage/LOGGED_IN'
export const LOGGED_OUT = 'authPage/LOGGED_OUT'

export const SET_EMAIL_FOR_RESTORE_PASSWORD = 'authPage/SET_EMAIL_FOR_RESTORE_PASSWORD'

export const SET_LOADING_TRUE = 'authPage/SET_LOADING_TRUE'
export const SET_LOADING_FALSE = 'authPage/SET_LOADING_FALSE'

export const SHOW_ERROR = 'authPage/SHOW_ERROR'
export const HIDE_ERROR = 'authPage/HIDE_ERROR'

export const SET_IMAGE_AND_PHONE = 'authPage/SET_IMAGE_AND_PHONE'

export const SET_USER = 'authPage/SET_USER'