import {GET_REALTY_ACTION, GET_REALTY_SAGA} from "../../actionTypes";
import {RealtyType, RealtyTypeAPI} from "../../../../../types/types";

export type GetRealtyType = {
  type: typeof GET_REALTY_ACTION,
  payload: {
    data: RealtyTypeAPI[]
  }
}

export const getRealty = (action: GetRealtyType) => ({
  type: action.type,
  payload: {
    data: action.payload.data
  }
})