import {initStateType} from "../../reducer";
import produce from "immer";
import {WritableDraft} from "immer/dist/types/types-external";
import {GetZonesType} from "../../actions/realty/getZones";
import {ZoneType} from "../../../../../types/types";


export const getZonesReducer = (state: initStateType, action: GetZonesType) =>
  produce(state, ((draft: WritableDraft<initStateType>) => {
    const zones = action.payload.zones.map((item): ZoneType => {
      return {
        file: item.file,
        id: item.id,
        order_id: item.order_id,
        title: item.title,
        realty_id: action.payload.realty_id
      }
    })
    draft.zones = zones
  }))