import createReducer from '../../../store/createReducer';
import { NotificationType, RealtyType, ZoneType } from '../../../types/types';

import * as reducers from './reducers';
import * as t from './actionTypes';
import { CardType, DndType } from '../../../types/cardTypes';
import { popupStatusTypes } from './sagas/poll/types';

export type initStateType = {
	realty: RealtyType[];
	zones: ZoneType[];
	dndCards: DndType | {};
	cards: CardType[];
	activeCard: CardType | {};
	notification: NotificationType[] | [];
	activeNotification: boolean;
	popupNotifications: Array<IPopupNotificationItem>;
	message: any;
	currentRealty: number;
	currentZone: number;
};

export interface IPopupNotificationItem {
	message: string;
	id: string;
	type: popupStatusTypes;
}

const initState: initStateType = {
	realty: [],
	zones: [],
	dndCards: {},
	cards: [],
	activeCard: {},
	notification: [],
	activeNotification: false,
	popupNotifications: [],
	message: '',
	currentRealty: 0,
	currentZone: 0,
};

const homePageReducer = createReducer(initState, {
	[t.GET_REALTY_ACTION]: reducers.getRealtyReducer,
	[t.GET_ZONES_ACTION]: reducers.getZonesReducer,
	[t.GET_ZONES_CARD_ACTION]: reducers.getZonesCardReducer,
	[t.NOTIFICATION_ACTION]: reducers.notificationReducer,
	[t.TOGGLE_NOTIFICATION_ACTION]: reducers.toggleNotificationReducer,
	[t.MESSAGE_ACTION]: reducers.setMessageReducer,
	[t.SET_POPUP_NOTIFICATION_ACTION]: reducers.setPopupNotification,
	[t.CLEAR_POPUP_NOTIFICATION_ACTION]: reducers.clearPopupNotification,
	[t.SET_CURRENT_REALTY]: reducers.setCurrentRealtyReducer,
	[t.SET_CURRENT_ZONE]: reducers.setCurrentZoneReducer,
});

export default homePageReducer;
