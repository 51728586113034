import React, { FC, useEffect, useState } from 'react';
import styles from './all-off.module.scss';
import { AllOffBlockType, BlockType } from '../../../../../../types/cardTypes';
import NoVariable from '../no-variable/no-variable';
import OnlineStatus from '../online-status/online-status';

type AllOffType = {
	item: AllOffBlockType;
	patchVariableBlock: (block: AllOffBlockType, value, type) => void;
};

const AllOff: FC<AllOffType> = ({ item, patchVariableBlock }) => {
	const [disabled, setDisabled] = useState(
		item.variable.value === null ? true : false,
	);
	const [checked, setChecked] = useState(
		Number(item.variable.value) > 0 || item.variable.value === true
			? true
			: false,
	);

	useEffect(() => {
		setChecked(
			Number(item.variable.value) > 0 || item.variable.value === true
				? true
				: false,
		);
	}, [item]);

	const handleAllOff = () => {
		// setDisabled(true)
		setChecked((checked) => !checked);
		patchVariableBlock(item, !checked, item.type);
	};

	return item.variable ? (
		<div className={styles.allOffWrapper}>
			<div className={styles.description}>
				{item.description}
				<OnlineStatus isOnline={item.variable.is_online_device} />
			</div>
			<button
				className={styles.descriptionButton}
				onClick={handleAllOff}
				type="button"
				style={{
					backgroundColor: checked ? '#60BF69' : '#8D998D',
					cursor: disabled ? 'default' : 'pointer',
				}}
				// disabled={disabled}
			>
				{checked ? item.true_description_button : item.false_description_button}
			</button>
		</div>
	) : (
		<NoVariable />
	);
};

export default AllOff;
