import { NOTIFICATION_ACTION } from "../../actionTypes";
import { NotificationType } from "../../../../../types/types";

export type NotificationActionType = {
  type: typeof NOTIFICATION_ACTION,
  payload: {
    notifications: NotificationType[]
  }
}

export const notificationAction = (data: NotificationActionType) => ({
  type: data.type,
  payload: data.payload,
})