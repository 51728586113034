import {all, call} from "@redux-saga/core/effects";

import {
  getRealtySagas,
  getZonesSagas,
  getZonesCardsSagas,
  getNotificationSagas,
  patchZonesCardSagas,
  patchCardSagas,
  patchNotificationSagas,
} from './poll'

export function* homePageSagas() {
  yield  all([
    call(getRealtySagas),
    call(getZonesSagas),
    call(getZonesCardsSagas),
    call(getNotificationSagas),
    call(patchZonesCardSagas),
    call(patchCardSagas),
    call(patchNotificationSagas),
  ])
}